import { ReactNode } from "react";
import { AuthOAuth } from "./AuthOAuth";
import { AuthPasswd } from "./AuthPasswd";

export interface Account {
    account_id: number,
    company_id: number,
    uuid: string,
    email: string,
    firstname: string,
    lastname: string,
    is_verified: boolean,
    roles: string
}

export interface AuthUser {
    username: string,
    password: string
}

export interface AuthProviderProps {
    children: ReactNode;
}

export type Screen = 'auth' | 'user' | 'admin';

export interface ContextModel {
    account: Account | null,
    sessionId: string | null,
    setContext: any
}


export type AuthMethodKey = 'OAUTH' | 'PASSWD';

export const AuthMethod = {
    OAUTH: new AuthOAuth(),
    PASSWD: new AuthPasswd(),
}

export interface AuthContextData {
    user?: Account;
    signIn: (method: AuthMethodKey, user?: AuthUser) => void;
    signOut: () => void;
    isAuthenticated: boolean;
    isVerified: boolean;
    getAuthMethodType: () => string;
}

export enum UserRoles {
    Owner = 'owner',
    Admin = 'admin',
    User = 'user'
}

