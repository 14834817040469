import React from 'react';

const AccountRequiresVerification: React.FC<{}> = () => {    
    return (
        <div>
          <h3>Your account requires verification. Please check your email for the verification email and click the link.</h3>  
        </div>
    );
}

export default AccountRequiresVerification;