import React from 'react';
import {useEffect} from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';

import axios from 'axios'
import { toast } from 'react-toastify';
axios.defaults.withCredentials = true;

interface AccountVerifyData {
    accountId: number,
    code: string,
    requiresPassword: boolean
}

const API_URL = '/api/login/verify';

const VerifyAccount: React.FC<{}> = () => {
    const navigate = useNavigate();
    let [searchParams] = useSearchParams();
    
    let verificationDone = false;

    useEffect(() => {     
        if (verificationDone) {
            return;
        }

        verificationDone = true;        

        const code = searchParams.get('code');
        const accountId = searchParams.get('accountId');

        if (!accountId || !code) {
            throw new Error('There was a problem verifying your account.');
        }

        const doVerify = async () => {
            try {
                return await axios.post<AccountVerifyData>(
                    API_URL, {
                        accountId,
                        code,
                    });               
            } catch(err) {
                console.error(err);

                toast.error('There was a problem verifying your account.');
            }
        };

        doVerify().then((response) => {      
            if (response && 
                response.status === 200) {

                    toast.success('Thank you for verifying your account.');

                if (response.data.requiresPassword) {
                    navigate('/create_password');
                } else {
                    navigate('/call_recordings');
                }
                
                return;
            }
        }).catch(() => {            
            toast.error('There was a problem verifying your account.');
            navigate('/sign-in')
        }).finally(() => {            
        });
    });

    return (
        <Outlet />
    );
}

export default VerifyAccount;
