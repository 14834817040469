import React from "react";
import "./loader.css";
import { useLoading } from "../../context/LoadingContext";

const Loader: React.FC = () => {
  const loader = useLoading();

  if (!loader.isLoading) {
    return <React.Fragment />;
  }

  return (
    <div className="loader ring4" >
      <div className="loader ring3" >
        <div className="loader ring2" >
          <div className="loader ring1" >
            <div className="loader ring0" >
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
