import React from "react";
import { useState, useEffect } from "react";
import Markdown from 'marked-react';

const API_CHAT_LIST_FEEDBACK = "/api/chat_feedback/";

interface FeedbackRecord {
    id: number;
    createdAt?: Date
    customer_id?: string;
    person_id?: string;
    question: string;
    answer: string;
    feedback: string;
    rating: number;
    firstname?: string;
    lastname?: string;
}

interface FeedbackIndex {
    chat_feedbacks?: FeedbackRecord[];
}

const ChatFeedback: React.FC<{}> = () => {
    const [feedbacks, setFeedbacks] = useState<FeedbackRecord[]>([]);

    const fetchFeedbacks = async () => {
        try {
            const response = await fetch(API_CHAT_LIST_FEEDBACK, {
                headers: {
                    'Cache-Control': 'no-cache'
                }
            }
            );
            const data: FeedbackIndex = await response.json();
            setFeedbacks(data?.chat_feedbacks || []);
        } catch (error) {
            console.error('Error fetching feedbacks:', error);
        }
    };

    useEffect(() => {
        fetchFeedbacks();
    }, []);

    return (
        <div className="chat_feedback table-container">
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Created at</th>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Feedback</th>
                        <th>Rating</th>
                        <th>Author</th>
                    </tr>
                </thead>
                <tbody>
                    {feedbacks.map(feedback => (
                        <tr key={feedback.id}>
                            <td>{feedback.id}</td>
                            <td>{feedback?.createdAt?.toLocaleString()}</td>
                            <td>{feedback.question}</td>
                            <td><Markdown>{feedback.answer}</Markdown></td>
                            <td>{feedback.feedback}</td>
                            <td>{feedback.rating}</td>
                            <td>{`${feedback.firstname || ""} ${feedback.lastname || ""}`}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button onClick={fetchFeedbacks}>Update Feedbacks</button>
        </div>
    );
}

export default ChatFeedback;
