import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const ProtectedRoute = () => {
  const auth = useAuth();
  if (!auth || !auth.isAuthenticated
    || auth.user?.roles.includes("auth"))
    return <Navigate to="/sign_in" />;
  return <Outlet />;
};

export default ProtectedRoute;
