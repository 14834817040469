import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLoading } from "../context/LoadingContext";
import ConfirmationModal from "./ConfirmationModal";

const CREATE_TEXT_SQL_QUERY = "/api/prompt_sql/sql-query"; //process.env.REACT_APP_BACKEND_BASE_URL

const TextComponent: React.FC = () => {
  const [textQuery, setTextQuery] = useState<string>("");
  const [sqlQuery, setSqlQuery] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const { setLoading } = useLoading();

  const handleSave = async () => {
    const payload = {
      text_query: textQuery,
      sql_query: sqlQuery,
    };
    setLoading(true);
    try {
      let response: any = await fetch(
        CREATE_TEXT_SQL_QUERY,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      response = await response.json();

      if (response.success) {
        toast.success(response.message);
        setTextQuery("");
        setSqlQuery("");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error occured!");
    } finally {
      setShowModal(false);
      setLoading(false);
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="col-md-6 py-2 mb-3">
        <label
          htmlFor="exampleFormControlTextarea1"
          className="form-label font-medium"
        >
          Text
        </label>
        <textarea
          className="form-control resize-none"
          id="exampleFormControlTextarea1"
          placeholder="Type your text here..."
          rows={5}
          value={textQuery}
          onChange={(e) => setTextQuery(e.target.value)}
        />
      </div>
      <div className="col-md-6 py-2 mb-3">
        <label
          htmlFor="exampleFormControlTextarea2"
          className="form-label font-medium"
        >
          SQL Query
        </label>
        <textarea
          className="form-control resize-none"
          placeholder="Type your SQL Query here..."
          id="exampleFormControlTextarea2"
          rows={5}
          value={sqlQuery}
          onChange={(e) => setSqlQuery(e.target.value)}
        />
        <button
          type="button"
          className="btn btn-info  mt-3 float-end text-white"
          onClick={handleShowModal}
          disabled={!textQuery || !sqlQuery}
        >
          Save
        </button>
      </div>
      <ConfirmationModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handleSave={handleSave}
      />
    </>
  );
};

export default TextComponent;
