import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { toast } from "react-toastify";

const UPDATE_SQL_LIST = "/api/prompt_sql/sql-query/"; // process.env.REACT_APP_BACKEND_BASE_URL
const TEXT_SQL_QUERY_TABLE_LIST = "/api/prompt_sql/sql-query";

interface QueryData {
  id: number;
  text_query: string;
  sql_query: string;
  is_ddl: boolean;
  process_status: number;
  created_at: string;
  updated_at: string;
}

interface QueryListProps {
  queryListTableData: QueryData[];
  setQueryListTableData: (queryListTableData: QueryData[]) => void;
}

const QueryListTableDisplay: React.FC<QueryListProps> = ({
  queryListTableData = [],
  setQueryListTableData,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<QueryData | null>(null);
  const [totalRecords, setTotalRecords] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsLimit, setRowsLimit] = useState<number>(10);

  const onHandleDetail = (row: {
    id: number;
    text_query: string;
    sql_query: string;
    is_ddl: boolean;
    process_status: number;
    created_at: string;
    updated_at: string;
  }) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const fetchQueryList = async (page = 1, limit = 10) => {
    try {
      const response = await fetch(
        `${TEXT_SQL_QUERY_TABLE_LIST}?limit=${limit}&page=${page}`
      );
      if (response.ok) {
        const data = await response.json();
        setQueryListTableData(data?.query_list);
        setTotalRecords(data?.total);
      } else {
        console.error("Failed to fetch query list data");
      }
    } catch (error) {
      console.error("Error fetching query list data:", error);
    }
  };

  useEffect(() => {
    fetchQueryList();
  }, []);

  const handleClose = () => {
    setShowModal(false);
    setSelectedRow(null);
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  const handleTextQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRow((prev) => ({
      ...prev!,
      text_query: e.target.value,
    }));
  };

  const handleSqlQueryChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSelectedRow((prev) => ({
      ...prev!,
      sql_query: e.target.value,
    }));
  };

  const handleUpdateTextSQLQuery = async () => {
    if (!selectedRow) return;

    const { id, text_query, sql_query } = selectedRow;

    try {
      let response: any = await fetch(
        `${UPDATE_SQL_LIST}${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ text_query, sql_query }),
        }
      );
      response = await response.json();
      if (response.success) {
        toast.success(response.message);
        handleClose();
        fetchQueryList(currentPage, rowsLimit);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Error occured!");
    }
  };

  const columns: any = [
    {
      name: "ID",
      selector: (row: any) => row.id,
      sortable: true,
      cell: (row: any) => <div className="fs-5">{row.id}</div>,
      wrap: true,
      width: "70px",
    },
    {
      name: "Text Query",
      selector: (row: any) => row.text_query,
      sortable: true,
      cell: (row: any) => <div className="fs-5">{row.text_query}</div>,
      wrap: true,
    },
    {
      name: "SQL Query",
      selector: (row: any) => row.sql_query,
      sortable: true,
      cell: (row: any) => (
        <div className="fs-5" title={row.sql_query}>
          {truncateText(row.sql_query, 50)}
        </div>
      ),
      wrap: true,
    },
    {
      name: "Action",
      cell: (row: any) => (
        <i
          className="fa fa-edit text-success fs-4"
          aria-hidden="true"
          style={{ cursor: "pointer" }}
          onClick={() => onHandleDetail(row)}
        />
      ),
      ignoreRowClick: true,
      allowoverflow: true,
      button: "true",
    },
  ];

  return (
    <div className="container mt-4 border border-3 p-3">
      <h2 className="mb-4">Query List</h2>
      <div style={{ maxHeight: "400px", overflowY: "auto" }}>
        <DataTable
          columns={columns}
          data={queryListTableData}
          pagination
          paginationTotalRows={totalRecords}
          paginationServer
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsLimit}
          paginationRowsPerPageOptions={[10, 15, 20]}
          onChangePage={(page) => {
            if (currentPage != page) {
              setCurrentPage(page);
              fetchQueryList(page, rowsLimit);
            }
          }}
          onChangeRowsPerPage={(currentRowsPerPage, page) => {
            if (rowsLimit != currentRowsPerPage) {
              setRowsLimit(currentRowsPerPage);
              fetchQueryList(page, currentRowsPerPage);
            }
          }}
          highlightOnHover
          className="table table-striped fs-5"
        />
      </div>

      {selectedRow && (
        <div
          className={`modal fade ${showModal ? "show d-block" : "d-none"}`}
          tabIndex={-1}
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit {selectedRow.text_query}</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={handleClose}
                />
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="textQuery" className="form-label">
                    Text Query:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="textQuery"
                    value={selectedRow.text_query}
                    onChange={handleTextQueryChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="sqlQuery" className="form-label">
                    SQL Query:
                  </label>
                  <textarea
                    className="form-control"
                    id="sqlQuery"
                    rows={5}
                    value={selectedRow.sql_query}
                    onChange={handleSqlQueryChange}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={handleUpdateTextSQLQuery}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QueryListTableDisplay;
