import React, {useState, useEffect} from "react";
import { useLoading } from "../context/LoadingContext";

import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';

import axios from 'axios'
axios.defaults.withCredentials = true;

const API_URL = '/api/account_audit_logs';

interface AccountAuditLog {
    account_audit_log_id: number,
    uuid: string,
    account_id: number,
    action: string,
    details: string,
    created_on: string,
    created_by: string
}

interface AccountAuditLogPaginated {
    count : number,
    allAuditLogs: AccountAuditLog[]
}

const AccountAuditLogs: React.FC<{}> = () => {
    const { setLoading } = useLoading();
    let [auditLogs, setAuditLogs] = useState<AccountAuditLog[]>([]);
    let [skip, setSkip] = useState(0);
    let [take] = useState(20);
    let [page, setPage] = useState(1);
    let [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        setLoading(true);

        const loadAuditLogs = async() => {
            const response = await axios.get<AccountAuditLogPaginated>(
                `${API_URL}?skip=${skip}&take=${take}`
            );
    
            return response;
        };
    
        loadAuditLogs()
            .then(res => {                
                setAuditLogs(res.data.allAuditLogs);
               
                let pageCount = Math.floor((res.data.count - 1) / take) + 1;
                setTotalPages(pageCount);
            })
            .catch(error => {
                console.error({
                    message: 'Error fetching audit logs',
                    error
                });
                
                return;
            }).finally(() => {
                setLoading(false);
            });
     }, [skip, take]);


    function previousPage(e: any) {
        e.preventDefault();

        if (page <= 1) {
            return;
        }

        setSkip(skip - take);
        setPage(page - 1);     
    }
    
    function nextPage(e: any) {
        e.preventDefault();

        if (page >= totalPages) {
            return;
        }

        setSkip(skip + take);
        setPage(page + 1);        
    }       
    
    return (
        <div className="account-audit-logs">        
            <Stack direction="horizontal" gap={2}>
                <Button onClick={previousPage}>
                    Previous
                </Button>
                <Button onClick={nextPage}>
                    Next
                </Button>
                <div>
                    Page {page} of {totalPages}
                </div>
            </Stack>
            <Table striped bordered hover style={{fontSize: '0.9em'}}>
                <thead>
                    <tr>
                        <th style={{width: '20%'}}>Id</th>
                        <th style={{width: '20%'}}>Action</th>
                        <th style={{width: '20%'}}>Details</th>
                        <th style={{width: '20%'}}>Created On</th>
                        <th style={{width: '20%'}}>Created By</th>
                    </tr>
                </thead>
                <tbody>
                {auditLogs.map((el) => {
                    return(
                        <tr key={el.account_audit_log_id}>
                            <td className="align-top">{el.account_audit_log_id}</td>
                            <td className="align-top">{el.action || ''}</td>
                            <td className="align-top">
                                <div style={{ whiteSpace: 'pre-wrap' }}>
                                {JSON.stringify(el.details, null, 4)}
                                </div>
                            </td>
                            <td className="align-top">{el.created_on}</td>
                            <td className="align-top">{el.created_by}</td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        </div>
    );
}

export default AccountAuditLogs;
