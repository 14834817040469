import "./App.css";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import "./assets/chat_form.css";
import Navbar from "./components/Navbar";

import Chat from "./pages/Chat";
import ChatFeedback from "./pages/ChatFeedback";
import ValidationQuestions from "./pages/ValidatorQuestions";
import ValidationMetrics from "./pages/ValidatorMetrics";
import PromptsSql from "./pages/PromptsSql";
import Users from "./pages/Users";
import CallRecordings from "./pages/CallRecordings";
import CallRecordingDetails from "./pages/CallRecordingDetails";
import AccountAuditLogs from "./pages/AccountAuditLogs";
import Integrations from './pages/Integrations';
import SignUp from "./pages/SignUp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Loader from "./components/loader/Loader";
import { LoadingProvider } from "./context/LoadingContext";
import SignIn from './pages/SignIn';
import SignOut from './pages/SignOut';
import VerifyAccount from './pages/VerifyAccount';
import CreatePassword from './pages/CreatePassword';
import AccountRequiresVerification from './pages/AccountRequiresVerification';
import ProtectedRoute from "./components/ProtectedRoute";
import OverviewWorkflow from './pages/OverviewWorkflow';
import ConnectOAuth from './pages/ConnectOAuth';

import { AuthProvider } from "./context/Auth/AuthProvider";
import { UpdateInformer } from "./components/UpdateInformer";

function App() {
  return (
    <LoadingProvider>
      <ToastContainer />
      <Router>
        <AuthProvider>
          <Navbar />
          <UpdateInformer />
          <Loader />
          <Routes>
            <Route path="/sign_up" element={<SignUp />} />
            <Route path="/sign_in" element={<SignIn />} />
            <Route path="/oauth" element={<ConnectOAuth />} />
            <Route path="/account_requires_verification" element={<AccountRequiresVerification />} />
            <Route path="/verify_account" element={<VerifyAccount />} />
            <Route path="/create_password" element={<CreatePassword />} />

            <Route element={<ProtectedRoute />}>
              <Route path="/chat" element={<Chat />} />
              <Route path="/chat_feedback" element={<ChatFeedback />} />
              <Route path="/rag_questions" element={<ValidationQuestions />} />
              <Route path="/rag_metrics" element={<ValidationMetrics />} />
              <Route path="/prompts_sql" element={<PromptsSql />} />        
              <Route path="/call_recordings" element={<CallRecordings />} />
              <Route path="/call_recording_details" element={<CallRecordingDetails />} />
              <Route path="/account_audit_logs" element={<AccountAuditLogs />} />
              <Route path="/integrations" element={<Integrations />} />
              <Route path="/overview_workflow" element={<OverviewWorkflow />} />
              <Route path="/users" element={<Users />} />
              <Route path="/sign_out" element={<SignOut />} />
              <Route path="/" element={<Navigate to="/chat" />} />
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
    </LoadingProvider>
  );
}

export default App;
