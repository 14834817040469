import { useState, FC, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useAuth } from '../hooks/useAuth';

interface ChatInputProps {
  onSendMessage: (message: ChatQuery) => void;
  disabled: boolean;
}

export interface ChatQuery {
  text: string;
  customer_id: string;
  useWebSearch?: boolean;
}

const ChatInput: FC<ChatInputProps> = ({ onSendMessage, disabled }) => {
  const { user } = useAuth();

  const [formData, setFormData] = useState<ChatQuery>({
    text: "",
    customer_id: "",
    useWebSearch: false,
  });

  const [options, setOptions] = useState<Array<string>>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  useEffect(() => {
    let arr: any = [];
    if (user && user.roles?.includes('SuperAdmin')) {
      arr = ['Dotin', 'Enboarder', 'Enboarder POC'];
    } else {
      arr = ['Enboarder POC'];
    }

    setOptions(arr);
    if (arr.length > 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        customer_id: arr[0]
      }));
    }
  }, [setOptions])

  const handleSendMessage = () => {
    if (formData.text.trim() === '') return;
    onSendMessage({
      ...formData,
      text: formData.text.trim(),
    });
    setFormData({
      ...formData,
      text: ""
    });
  };

  return (
    <Form className="chat-input">
      <Form.Group controlId="formMessage">
        <Form.Control
          as="textarea"
          name="text"
          rows={3}
          value={formData.text}
          onChange={handleChange}
          placeholder="Type your message..."
        />
      </Form.Group>

      <Row className="d-flex align-items-center justify-content-between">
        <Col md="auto">
          <Form.Select name="customer_id" value={formData.customer_id} onChange={handleChange}>
            <option value="" disabled>Select Customer</option>
            {
              options.map((opt, i) => {
                return (<option key={i} value={opt}>{opt}</option>);
              })
            }
          </Form.Select>
        </Col>
        <Col xs="auto">
          <Form.Check name="useWebSearch" label="Use web search" checked={formData.useWebSearch} onChange={handleCheckboxChange} />
        </Col>
        <Col xs="auto">
          <Button variant="primary" onClick={handleSendMessage} disabled={disabled}>Send</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ChatInput;
