import { FC, useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useAuth } from '../hooks/useAuth';


interface ChatInputProps {
    currentLimit: number;
    setLimit: (number: number) => void;
    cleanupHistory: () => void;
}

const ChatHistoryControl: FC<ChatInputProps> = ({ cleanupHistory, setLimit, currentLimit }) => {
    const [formMessagesLimit, setFormMessagesLimit] = useState(0);
    const { user } = useAuth();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        let newVal = parseInt(value);
        setFormMessagesLimit(!isNaN(newVal) ? newVal : 0);
    };
    const clickOnSetLimit = () => {
        setLimit(formMessagesLimit);
    }

    useEffect(() => {
        setFormMessagesLimit(currentLimit);
    }, [currentLimit])

    return (
        <Row className="d-flex align-items-center justify-content-between">
            {user!.roles.includes('SuperAdmin') && (
                <Col md="auto">
                    <InputGroup>
                        <InputGroup.Text id="messages-limit">Limit of messages</InputGroup.Text>
                        <Form.Control
                            placeholder="50"
                            aria-label="50"
                            aria-describedby="messages-limit"
                            onChange={handleChange}
                            value={formMessagesLimit}
                        />
                        <Button variant="success" size="sm" onClick={clickOnSetLimit}>Set</Button>
                    </InputGroup>
                </Col>)
            }
            <Col xs="auto" className="mb-1">
                <Button variant="outline-danger" size="sm" onClick={cleanupHistory}>Cleanup history</Button>
            </Col>
        </Row>
    )
}

export default ChatHistoryControl;
