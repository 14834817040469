import React from 'react';
import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import axios from 'axios'
import { toast } from 'react-toastify';
axios.defaults.withCredentials = true;

const API_URL = '/api/login/signup';

interface UserInfo {
    username: string,
    passsword: string,
    roles: string
}

const SignUp: React.FC<{}> = () => {
    const navigate = useNavigate();

    let [username, setUsername] = useState<string>('');
    let [password, setPassword] = useState<string>('');
    let [confirmPassword, setConfirmPassword] = useState<string>('');

    const doSignUp = async () => {
        if (password != confirmPassword) {
            toast.error(`Passwords don't match`);
            return;
        }

        try {
            const response = await axios.post<UserInfo>(
                API_URL, {
                    username,
                    password,
                    roles: 'owner'
                });

            if (response.status === 200) {
                toast.success('Please check your e-mail for a verification link to activate your account.');
                
                navigate('/account_requires_verification')
                return;
            }

            toast.error('There was an error signing up');

        } catch(err) {
            console.error(err);
            
            toast.error('There was an error signing up');
        }
    };

    return (
        <div className="sign-up-container">
            <Container className="text-center">
                <Row>
                    <Col>
                    </Col>
                    <Col>
                        <h1>Sign up</h1>
                        <Form>
                            <Form.Group className="mb-3" controlId="username">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="name@example.com" 
                                    value={username || ''}
                                    onChange={event => setUsername(event.target.value)}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" 
                                    value={password || ''}
                                    onChange={event => setPassword(event.target.value)}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="confirmPassword">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" 
                                    value={confirmPassword || ''}
                                    onChange={event => setConfirmPassword(event.target.value)}/>
                            </Form.Group>
                        </Form>
                        <Button onClick={async () => await doSignUp()}>Sign Up</Button>
                    </Col>
                    <Col>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SignUp;
