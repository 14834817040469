import { useState, useEffect } from 'react';

interface ValResults  {
    id: number;
    question_id: number;
    answer: string;
    createdAt: Date;
    factual_correctness: number;
    semantic_similarity: number;
    answer_correctness: number;
};

interface ValResultsIndex {
    validation_results?: ValResults[];
}

type ValQuestions = {
    id: number;
    question: string;
    ground_truth: string;
    customer_id: string;
    created_at?: Date;
    updated_at?: Date;
    process_status: number;
}

interface ValQuestionsIndex {
    validation_questions?: ValQuestions[];
}

interface ValOutput extends ValResults, ValQuestions {} 

interface ValOutputDict {
    id: number,
    value: ValOutput[];
}

const ValidationMetrics: React.FC<{}> = () => {
    const [valOutput, setValOutput] = useState<ValOutput[]>([]);
    const [valResOutput, setValResQutput] = useState<ValOutputDict[]>([]);

    const fetchValResults = async () => {
        try {
            const response = await fetch('/api/validation/results', {
                headers: {
                    'Cache-Control': 'no-cache'
                }
            }
            );
            const response_q = await fetch('/api/validation/', {
                headers: {
                    'Cache-Control': 'no-cache'
                }
            }
            );
            const data: ValResultsIndex = await response.json();

            let valResults = data?.validation_results || [];

            const data_q: ValQuestionsIndex = await response_q.json();

            let valQuestions = data_q?.validation_questions || [];

            let var_valOutput : ValOutput[] = [];
            for(let i=0; i<valQuestions.length; i++) {
                let q_id = valQuestions[i]["id"];
                let res = valResults.filter((itmInner:ValResults) => itmInner.question_id === q_id).pop();
                var_valOutput.push(Object.assign({}, res, valQuestions[i]));
            };

            var_valOutput.sort((a, b) => a.id - b.id);
            setValOutput(var_valOutput);
            
            let valOutput_all : ValOutput[] = [];
            for(let i=0; i<valResults.length; i++) {
                let q_id = valResults[i]["question_id"];
                let res = valQuestions.filter((itmInner:ValQuestions) => itmInner.id === q_id).pop();
                valOutput_all.push(Object.assign({}, valResults[i], res));
            };

            let var_valResOutput: ValOutputDict[] = [];
            for(let i=0; i<valQuestions.length; i++) {
                let q_id = valQuestions[i]["id"];
                let elements = valOutput_all.filter((itmInner:ValOutput) => itmInner.question_id === q_id)
                let res: ValOutputDict = {
                    id: q_id, 
                    value: elements
                }
                var_valResOutput.push(res);
            };

            setValResQutput(var_valResOutput);

        } catch (error) {
            console.error('Error fetching validation results:', error);
        }
    };
     
    useEffect(() => {
        fetchValResults();
    }, []);

    return (
        <div>
        <button onClick={fetchValResults}>Update Validation Results</button>
        
        <div className="AppTable">
            
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Question ID</th>
                        <th>Question</th>
                        <th>Ground truth</th>
                        <th>Answer</th>
                        <th>Factual correctness	</th>
                        <th>Semantic similarity	</th>
                        <th>Answer correcness</th>
                        <th>Created at</th>
                    </tr>
                </thead>
                <tbody>
                    {valOutput.map(val_question => (
                        <tr key={val_question.id}>
                            <td>{val_question.id}</td>
                            <td><a href={`#${val_question.question_id}`}>{val_question.question_id}</a></td>
                            <td>{val_question.question}</td>
                            <td>{val_question.ground_truth}</td>
                            <td className='td_white_space'>{val_question.answer}</td>
                            <td>{val_question.factual_correctness}</td>
                            <td>{val_question.semantic_similarity}</td>
                            <td>{val_question.answer_correctness}</td>
                            <td>{val_question?.created_at?.toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        
            {valResOutput.map(records => {
                return (
                    <div className="StatTable" id={(records.id).toString()}>
                        Question #{(records.id).toString()}
                        <table>
                            <thead>
                                <tr >
                                    <th>created at</th>
                                    <th>question</th>
                                    <th>ground truth</th>
                                    <th>answer</th>
                                    <th>factual correctness</th>
                                    <th>semantic similarity</th>
                                    <th>answer correctness</th>
                                </tr>
                            </thead>
                            <tbody>
                                {records.value.map(val => (
                                <tr key={val.id}>
                                    <td>{val.created_at?.toLocaleString()}</td>
                                    <td>{val.question}</td>
                                    <td>{val.ground_truth}</td>
                                    <td>{val.answer}</td>
                                    <td>{val.factual_correctness}</td>
                                    <td>{val.semantic_similarity}</td>
                                    <td>{val.answer_correctness}</td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                );
            })}
        </div>
        </div>
    );
}

export default ValidationMetrics;
