import React from "react";
import { useState, useEffect } from "react";
import Markdown from 'marked-react';
import '../assets/overview_workflow.css';

const API_OVERVIEW_LIST = "/api/overview_workflow/";

enum StatusType {
    not_required = "not_required",
    pending = "pending",
    in_progress = "in_progress",
    completed = "completed",
}

interface OverviewWorkflowRecord {
    overview_id: number;
    created_at?: Date;
    customer_id?: string;
    document_content?: string;
    document_overview?: string;
    citations?: string;
    title?: string;
    participants?: string;
    speaker_analytics?: string;
    metrics?: string;
    sentiment?: string;
    email_draft_status?: StatusType;
    email_draft_content?: string;
}

interface OverviewIndex {
    overviews_workflow?: OverviewWorkflowRecord[];
}

// function formatKeywordsBoldItalic(fullText: string, keywords: string[]): string {
//     // Create a regular expression that matches any of the keywords followed possibly by punctuation or spaces
//     const regex = new RegExp(`(${keywords.join('|')})(</?[a-z]+>)*\\s*(</?[a-z]+>)*(?=[\\s:.!?])`, 'gi');

//     console.log(`Using regex: ${regex}`);
//     // Replace each keyword match with its bold and italic version
//     return fullText.replace(regex, (match) => {
//         console.log(`Match found: ${match}`); // Log each match found
//         return `<strong><em>${match.trim()}</em></strong>`; // Apply bold and italic formatting
//     });
// }

function formatFirstSentenceBoldItalic(fullText: string, keywords: string[]): string {
    // Regex to capture the keyword, followed by optional HTML tags, any whitespace, and the first sentence.
    const regex = new RegExp(`(${keywords.join('|')})(<\/?[a-z]+>)*\\s*(<\/?[a-z]+>)*(.*?[\\.!?])(\\s*<br\s*\/?>)?`, 'gi');

    return fullText.replace(regex, (match, keyword, _tag1, _tag2, sentence, lineBreak) => {
        // Ensure the line break if it exists is maintained after formatting
        const formattedSentence = `<strong><em>${sentence.trim()}</em></strong>`;
        return `${keyword}<br>${formattedSentence}`;
    });
}

function parseAndBolden(text: string, field: string): string {
    // Define a regular expression pattern to find the text after the specified field until the end of the line/string
    const regex = new RegExp(`${field}:(.*)`, 'i');
    const match = text.match(regex);
    
    if (match && match[1]) {
      // Trim and manipulate the extracted text. For example, replacing underscores with spaces
      let modifiedText = match[1].trim().replace(/_/g, ' ');
      
      // Return the modified text wrapped in <strong> tags to make it bold
      return `<strong>${modifiedText}</strong>`;
    }
    
    return ''; // Return an empty string if no matching field is found
  }

// Main function to format text with newlines and specific stylings
function formatTextWithNewlines(text: string | undefined): JSX.Element[] {
    if (!text) {
        return [<span key="default">No text available</span>];
    }

    // Define patterns
    const boldOnlyPatterns = ['Summary:', 'Citations:', 'Key Points:', 'Subject:'];
    const newlineAndBoldPatterns = ['Outcomes:', 'Action Items/Next Steps:', 'Content:'];
    const boldItalicFirstSentencePatterns = ['Key Points:', 'Outcomes:', 'Action Items/Next Steps:', ];

    // Apply initial bold formatting
    let processedText = text.replace(new RegExp(`(${boldOnlyPatterns.join('|')})`, 'g'), '<strong>$1</strong>');
    processedText = processedText.replace(new RegExp(`(${newlineAndBoldPatterns.join('|')})`, 'g'), '\n<strong>$1</strong>');

    // processedText = parseAndBolden(processedText, 'Subject:');
    // Apply bold and italic to the first sentence after certain keywords
    // processedText = formatFirstSentenceBoldItalic(processedText, boldItalicFirstSentencePatterns);
    // processedText = processedText.replace(new RegExp(`(${newlineAndBoldPatterns.join('|')})`, 'g'), '\n<strong>$1</strong>');

    // Split the text for rendering and ensure newline management
    const lines = processedText.split('\n');
    return lines.map((item, index) => (
        <React.Fragment key={index}>
            <span dangerouslySetInnerHTML={{ __html: item }} />
            {index < lines.length - 1 && <br />}
        </React.Fragment>
    ));
}


const OverviewWorkflow: React.FC<{}> = () => {
    const [overviews, setOverviews] = useState<OverviewWorkflowRecord[]>([]);

    const fetchOverviews = async () => {
        try {
            const response = await fetch(API_OVERVIEW_LIST, {
                headers: {
                    'Cache-Control': 'no-cache'
                }
            });
            const data: OverviewIndex = await response.json();
            console.log("Overview data fetched from response:", data?.overviews_workflow);
            setOverviews(data?.overviews_workflow || []);
        } catch (error) {
            console.error('Error fetching overviews:', error);
        }
    };

    useEffect(() => {
        fetchOverviews();
    }, []);

    useEffect(() => {
        console.log("Overview data in state:", overviews);
    }, [overviews]);

    return (
        <div className="overview_workflow table-container">
            <table>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Content Overview</th>
                        <th>Citations</th>
                        <th>Participants</th>
                        <th>Call Analytics</th>
                        <th>Metrics</th>
                        <th>Sentiment</th>
                        <th>Email Draft</th>
                    </tr>
                </thead>
                <tbody>
                    {overviews.map(overview => (
                        <tr key={overview.overview_id}>
                        <td>{overview.title}</td>
                        <td>{formatTextWithNewlines(overview.document_overview)}</td>
                        <td>{formatTextWithNewlines(overview.citations)}</td>
                        <td>{overview.participants}</td>
                        <td>{overview.speaker_analytics}</td>
                        <td>{formatTextWithNewlines(overview.metrics)}</td>
                        <td>{overview.sentiment}</td>
                        <td>{formatTextWithNewlines(overview.email_draft_content) || 'No Email Draft'}</td>
                    </tr>
                    ))}
                </tbody>
            </table>
            <button onClick={fetchOverviews}>Refresh Data</button>
        </div>
    );
}

export default OverviewWorkflow;