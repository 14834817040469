import { Nav, NavLink, NavMenu } from "./NavbarElements";
import { useAuth } from "../hooks/useAuth"
 
const Navbar = () => {
    const {user, isAuthenticated} = useAuth();

    const isSuperAdmin = user && user.roles.includes('SuperAdmin');
      
    return (
        <Nav>
            <NavMenu>
                {isAuthenticated && (<NavMenu>
                {!isSuperAdmin && 
                (
                    <NavMenu>
                        
                        <img height={"80px"} src={"../aspr_logo_beta_80.png"} />
                        <NavLink to="/sign_out" style={{position: "absolute", right: "2em"}} activestyle="true">
                            Sign Out
                        </NavLink>
                    </NavMenu>
                )}                
                {isSuperAdmin && 
                (
                    <>
                        <NavLink to="/chat" activestyle="true">
                            Chat
                        </NavLink>
                        <NavLink to="/chat_feedback" activestyle="true">
                            Chat Feedback
                        </NavLink>
                        <NavLink to="/rag_questions" activestyle="true">
                            RAG Validator Questions
                        </NavLink>
                        <NavLink to="/rag_metrics" activestyle="true">
                            RAG Metrics
                        </NavLink>
                        <NavLink to="/prompts_sql" activestyle="true">
                            Prompts SQL
                        </NavLink>
                        <NavLink to="/call_recordings" activestyle="true">
                            Call Recordings
                        </NavLink>
                        <NavLink to="/account_audit_logs" activestyle="true">
                            Audit Logs
                        </NavLink>
                        <NavLink to="/integrations" activestyle="true">
                            Integrations
                        </NavLink>
                        <NavLink to="/overview_workflow" activestyle="true">
                            Call Overview
                        </NavLink>
                        <NavLink to="/users" activestyle="true">
                            Users
                        </NavLink>
                        <NavLink to="/sign_out" activestyle="true">
                            Sign Out
                        </NavLink>
                    </>
                )}
                </NavMenu>)}
                {isAuthenticated || (<NavMenu>
                    <img height={"80px"} src={"../aspr_logo_beta_80.png"} />
                    <NavLink to="/sign_in" style={{position: "absolute", right: "2em"}} activestyle="true">
                        Sign In
                    </NavLink>
                </NavMenu>)}
            </NavMenu>
        </Nav>
    );
};

export default Navbar;
