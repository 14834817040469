import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import React, {useRef, useState, useEffect, CSSProperties } from 'react';
import { useSearchParams } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import FormGroup from "react-bootstrap/FormGroup";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Accordion from 'react-bootstrap/Accordion';

import ReactPlayer from 'react-player'

import { Link } from "react-router-dom";

import { Trash } from 'react-bootstrap-icons';

import { ChevronLeft } from 'react-bootstrap-icons';
import { Person } from 'react-bootstrap-icons';
import { Copy } from 'react-bootstrap-icons';

import { useLoading } from "../context/LoadingContext";

import { CallAdvancedAnalytics, CallCoaching, CallCoachingSubject, CallSummary, MeetingInstance, ModalInfo, SpeakerSeconds } from "../types/types";
import { FormSelect } from "react-bootstrap";

import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import axios from 'axios'
axios.defaults.withCredentials = true;

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
  };

const API_URL = '/api/call_recordings';
const API_URL_SALESFORCE_MEETING_RECORDS = '/api/salesforce/meetingInstance';

const createCoachingAccordion = (cc: CallCoaching) => {
    return (
        <div>
            <Accordion className="mt-3">
                {createCoachingEntry(cc, CallCoachingSubject.RapportBuilding)}
                {createCoachingEntry(cc, CallCoachingSubject.Discovery)}
                {createCoachingEntry(cc, CallCoachingSubject.ActiveListening)}
                {createCoachingEntry(cc, CallCoachingSubject.ObjectionHandling)}
                {createCoachingEntry(cc, CallCoachingSubject.ValueEnginerring)}
                {createCoachingEntry(cc, CallCoachingSubject.ClosingTactics)}
            </Accordion>
        </div>        
    )
}
const createCoachingEntry = (cc: CallCoaching, subject: CallCoachingSubject) => {
    let headerText =  '';
    let bodyText =  '';
    let score = 0;

    switch(subject) {
        case CallCoachingSubject.Overview:
            headerText = 'Overview';
            bodyText = cc.coaching_overview;
            break;

        case CallCoachingSubject.RapportBuilding:
            headerText = 'Rapport Building';
            bodyText = cc.rapport_building;
            score = cc.rapport_building_score;
            break;

        case CallCoachingSubject.Discovery:
            headerText = 'Discovery';
            bodyText = cc.discovery;
            score = cc.discovery_score;
            break;

        case CallCoachingSubject.ActiveListening:
            headerText = 'Active Listening';
            bodyText = cc.active_listening;
            score = cc.active_listening_score;
            break;

        case CallCoachingSubject.ObjectionHandling:
            headerText = 'Objection Handling';
            bodyText = cc.objection_handling;
            score = cc.objection_handling_score;
            break;

        case CallCoachingSubject.ValueEnginerring:
            headerText = 'Value Engineering';
            bodyText = cc.value_engineering;
            score = cc.value_engineering_score;
            break;

        case CallCoachingSubject.ClosingTactics:
            headerText = 'Closing Tactics';
            bodyText = cc.closing_tactics;
            score = cc.closing_tactics_score
            break;
    }

    return (
        <Accordion.Item eventKey={subject}>
            <Accordion.Header>
                <Row style={{width: '100%'}}>
                    {subject != CallCoachingSubject.Overview &&
                    (
                        <>
                            <Col xs={6}>
                                {headerText}
                            </Col>
                            <Col xs={6}>
                                {createCoachingProgressBar(score)}
                            </Col>
                        </>
                    )}
                    {subject == CallCoachingSubject.Overview &&
                    (
                        <>
                            <Col>
                                {headerText}
                            </Col>
                        </>
                    )}
                </Row>                
            </Accordion.Header>
            <Accordion.Body>
                <div style={{ whiteSpace: 'pre-wrap' }}>
                    {formatTextWithNewlines(bodyText)}
                </div>                
            </Accordion.Body>
        </Accordion.Item>
    )
}

const createCoachingProgressBar = (score: number) => {
    let variant = 'success;'
    
    if (score <= 1) {
        variant = 'danger';
    }
    else if (score <= 3) {
        variant = 'warning';
    }

    return (
        <ProgressBar variant={variant} now={score * 20} />
    )
};

// Main function to format text with newlines and specific stylings
function formatTextWithNewlines(text: string | undefined): JSX.Element[] {
    if (!text) {
        return [<span key="default">No text available</span>];
    }

    // Define patterns
    const boldOnlyPatterns = ['Issue #', 'Summary:',  'Citations:', 'Key Points:', 'Subject:'];
    const newlineAndBoldPatterns = ['Citation:','Suggested improvement:', 'Outcomes:', 'Action Items/Next Steps:', 'Content:'];
    const boldItalicFirstSentencePatterns = ['Key Points:', 'Outcomes:', 'Action Items/Next Steps:', ];

    // Apply initial bold formatting
    let processedText = text.replace(new RegExp(`(${boldOnlyPatterns.join('|')})`, 'g'), '<strong>$1</strong>');
    processedText = processedText.replace(new RegExp(`(${newlineAndBoldPatterns.join('|')})`, 'g'), '\n<strong>$1</strong>');

    // Split the text for rendering and ensure newline management
    const lines = processedText.split('\n');
    return lines.map((item, index) => (
        <React.Fragment key={index}>
            <span dangerouslySetInnerHTML={{ __html: item }} />
            {index < lines.length - 1 && <br />}
        </React.Fragment>
    ));
}

const CallRecordings: React.FC<{}> = () => {
    const canvasRef : any = useRef(null);
    const contextRef : any = useRef(null);

    const { setLoading } = useLoading();

    const [searchParams] = useSearchParams();    
    const [meetingInstanceId, setMeetingInstanceId] = useState<number>(0);
    const [meetingInstance, setMeetingInstance] = useState<MeetingInstance | null>(null);
    const [callSummary, setCallSummary] = useState<CallSummary | null>(null);
    const [callCoaching, setCallCoaching] = useState<CallCoaching[]>([]);
    const [selectedCoacheeName, setSelectedCoacheeName] = useState<string>('');
    const [callAdvancedAnalytics, setCallAdvancedAnalytics] = useState<CallAdvancedAnalytics[]>([]);
    const [meetingName, setMeetingName] = useState<string>('');
    const [deletionReason, setDeletionReason] = useState<string>('');
    const [modalInfo, setModalInfo] = useState<ModalInfo>({showPrimaryButton: true, showSecondaryButton: true});    
    const [show, setShow] = useState(false);
    const [salesforceRecords, setSalesForceRecords] = useState<any[]>([]);
    const [selectedSalesForceRecord, setSelectedSalesForceRecord] = useState<any[]>([]);
    const [salesForceRecordsLoading, setsalesForceRecordsLoading] = useState<boolean>(false);
   
    const handleSalesForceRecordSearch = (query: string) => {
        setsalesForceRecordsLoading(true);
        
        console.log(query);

        const getSalesforceRecords = async (query: string) => {
            return await axios.get<any>(`${API_URL_SALESFORCE_MEETING_RECORDS}?recordName=${query}`);            
        };
    
        getSalesforceRecords(query)
            .then((res) => {
                setSalesForceRecords(res.data.records);                
            })
            .catch((err) => {
                console.error({
                    message: 'An error occured while fetching sales force records',
                    err
                });

                toast.error(err);
            }).finally(() => {
                setsalesForceRecordsLoading(false);
            });
      };
    
    const handleClose = () => {
        setShow(false);
    } 

    const handleShow = (modalInfo: ModalInfo) => {
        setModalInfo(modalInfo) ;
        setShow(true);
    }

    const handleAccept = async () => {
        if (modalInfo.onAccept) {
            try {
                await modalInfo.onAccept();
            } catch (err) {
                console.error(err);
                toast.error('A problem occured');
            }
        }

        setShow(false);
    }

    const askDeleteCall = async () => {
        if (deletionReason.length === 0) {
            handleShow({
                title: `Can't delete`,
                message: 'A reason for deletion is required',
                showPrimaryButton: false,                
                showSecondaryButton: true,
                secondaryButtonName: 'Close',
            });
            return;
        }

        handleShow({
            title: `Are you sure?`,
            message: `Once this call is deleted none of the associated data can be recovered. Are you sure you want to delete this call?`,
            showPrimaryButton: true,                
            primaryButtonName: 'Delete',                
            showSecondaryButton: true,
            secondaryButtonName: 'Close',
            onAccept: deleteCall
        });
    }

    const deleteCall = async () => {    
        if (!meetingInstance) {
            return;
        }

        const response = await axios.delete(
            `${API_URL}/meeting_instance/${meetingInstance.meeting_instance_id}`,
            {
                headers: {
                  'X-Delete-Reason': deletionReason
                },
            });
    }
            
    useEffect(() => {  
        setMeetingInstanceId(parseInt(searchParams.get('id') || '0'));
    });

    useEffect(() => {        
        if (!meetingInstance ||
            !meetingName || 
            meetingName.length <= 0) {
            return;
        }

        if (meetingName === meetingInstance.display_name) {
            return;
        }

        const saveMeetingName = async(meetingName: string) => {            
            if (!meetingInstance) {
                return;
            }

            const requestData = {
                meeting_instance_id: meetingInstance.meeting_instance_id,
                display_name: meetingName
            };
                
            const response = await axios.put(
                `${API_URL}/meeting_instance`,
                requestData
            );

            meetingInstance.display_name = meetingName;
    
            return response;
        };    

        const timeOutId = setTimeout(() => saveMeetingName(meetingName), 1000);
        return () => clearTimeout(timeOutId);
      }, [meetingName, meetingInstance]);
      
    useEffect(() => {
        if (!meetingInstance) {
            return;
        }
        
        setLoading(true);

        const meetingInstanceId = meetingInstance.meeting_instance_id;
        
        const getCallSummary = async () => {
            return await axios.get<CallSummary>(
                `${API_URL}/meeting_instance/${meetingInstanceId}/summary`
            );            
        };

        const organizeEmailContent = (data: any) => {
            const emailContent = data.email_draft_content;

            const indexOfContent = emailContent.indexOf('Content:');
            const subject = emailContent.substring(0,indexOfContent).replace('Subject:', '');

            data.email_draft_subject = subject;
            data.email_draft_content = emailContent.replace('Content:', '').substring(indexOfContent);
        }

        getCallSummary()
            .then((response) => {
                if (!response.data || !response.data.citations) {
                    response.data = {
                        citations: '',
                        document_overview: '',
                        email_draft_content: '',
                        email_draft_subject: '',
                        email_draft_status: '',
                        metrics: '',
                        participants: '',
                        sentiment: ''
                    };
                }
                else {
                    organizeEmailContent(response.data);
                }

                setCallSummary(response.data);
            })
            .catch((err) => {
                console.error({
                    message: 'An error occured while fetching call summary',
                    err
                });

                const emptySummary = {
                    citations: '',
                    document_overview: '',
                    email_draft_content: '',
                    email_draft_subject: '',
                    email_draft_status: '',
                    metrics: '',
                    participants: '',
                    sentiment: ''
                };

                setCallSummary(emptySummary);

                toast.error(err);
            }).finally(() => {
                setLoading(false);
            });          

    }, [meetingInstance]);

    useEffect(() => {
        if (!meetingInstance) {
            return;
        }
        
        setLoading(true);

        const meetingInstanceId = meetingInstance.meeting_instance_id;
        
        const getCallCoaching = async () => {
            return await axios.get<CallCoaching[]>(
                `${API_URL}/meeting_instance/${meetingInstanceId}/coaching`
            );            
        };
       
        getCallCoaching()
            .then((response) => {
                setCallCoaching(response.data);
                
                if (response.data.length >0) {
                    setSelectedCoacheeName(response.data[0].coachee_name);
                }
            })
            .catch((err) => {
                console.error({
                    message: 'An error occured while fetching call coaching',
                    err
                });

                toast.error(err);
            }).finally(() => {
                setLoading(false);
            });          

    }, [meetingInstance]);

    useEffect(() => {
        if (!meetingInstance) {
            return;
        }
        
        setLoading(true);

        const meetingInstanceId = meetingInstance.meeting_instance_id;
        
        const getCallAdvancedAnalytics = async () => {
            return await axios.get<CallAdvancedAnalytics[]>(
                `${API_URL}/meeting_instance/${meetingInstanceId}/advancedAnalytics`
            );            
        };
       
        getCallAdvancedAnalytics()
            .then((response) => {
                setCallAdvancedAnalytics(response.data);
            })
            .catch((err) => {
                console.error({
                    message: 'An error occured while fetching call advanced analytics',
                    err
                });

                toast.error(err);
            }).finally(() => {
                setLoading(false);
            });          

    }, [meetingInstance]);

    useEffect(() => {
        if (meetingInstanceId == 0) {
            return;
        }

        setLoading(true);

        const getMeetingInstance = async() => {
            const response = await axios.get<MeetingInstance>(
                `${API_URL}/meeting_instance/${meetingInstanceId}`
            );

            return response;
        };

        const backgroundLineColor = '#EAECF0';
        const neutralTextColor = '#667085';

        const speakerColors = [
            '#7F56D9',
            '#079455',
            '#55ADD6',
            '#D89258',
            '#2D46FF',
        ]

        const str_pad_left = (s : string, pad: string, length :number) => {
            return (new Array(length + 1).join(pad) + s).slice(-length);
        }

        const lerp = (
            value: number, 
            newMin: number,
            newMax: number, 
            oldMin: number, 
            oldMax: number) => {
            return (newMax - newMin) * (value - oldMin) / (oldMax - oldMin) + newMin;
        }

        const drawSpeakerTimeline = (meetingInstance: MeetingInstance) => {
            if (!canvasRef.current) {
                return;
            }

            const canvas = canvasRef.current;
            canvas.style.width = `${550}px`;
            canvas.height = 0;            
            canvas.style.height = `${0}px`;
            let context = canvas.getContext('2d');
            contextRef.current = context;
            context.clearRect(0, 0, canvas.width, 2000);

            const callAnalytics = meetingInstance.call_analytics;

            if (!callAnalytics || 
                !callAnalytics.speakingSecondsPerSpeaker) {
                return;
            }

            const transcriptionText = meetingInstance.transcription_text;
            

            if (!transcriptionText || 
                transcriptionText.length === 0) {
                return;
            }

            const spacePerSpeaker = 40;
            canvas.width = 550;
            const barWidth = canvas.width;
            const barHeight = 10;

            const sy = 20;
            const height = (callAnalytics.speakingSecondsPerSpeaker.length * spacePerSpeaker) + sy;
            canvas.height = height;            
            canvas.style.height = `${height}px`;

            context.scale(1, 1);
            context.lineCap = "round";                        
            context.font = 'bold 0.9em sans-serif';

            const speakerColorMap : any = {};
            const speakerIndexMap : any = {};
            
            let cx = 0;
            let cy = sy;
            let colorIndex = 0;
            for(let i = 0; i < callAnalytics!.speakingSecondsPerSpeaker!.length;i++) {
                const speakerSeconds = callAnalytics.speakingSecondsPerSpeaker[i];

                context.fillStyle = backgroundLineColor;
                context.fillRect(cx, cy, barWidth, barHeight);

                context.fillStyle = speakerColors[colorIndex];                
                context.fillText(speakerSeconds.speakerName, cx, cy - 2);

                let nameWidth = context.measureText(speakerSeconds.speakerName).width;

                context.fillStyle = neutralTextColor;
                const talkPercentage = ((100 * speakerSeconds.speakingSeconds / callAnalytics.totalSpeakingSeconds!).toFixed(2) + '%');

                const minutes = Math.floor(speakerSeconds.speakingSeconds / 60).toFixed(0);
                const seconds = (speakerSeconds.speakingSeconds % 60).toFixed(0);
                const finalTime = str_pad_left(minutes.toString(), '0', 2) + ':' + str_pad_left(seconds.toString(), '0', 2);
                
                context.fillText(`Talk Time: ${talkPercentage} (${finalTime})`, cx + nameWidth + spacePerSpeaker, cy - 2);

                speakerColorMap[speakerSeconds.speakerName] = colorIndex;
                speakerIndexMap[speakerSeconds.speakerName] = i;

                cy += spacePerSpeaker;
                colorIndex++;
                if (colorIndex >= speakerColors.length) {
                    colorIndex = 0;
                }
            }    

            const sentences : any = [];
            const transcriptionSentences = transcriptionText.split('\n');
            let minSeconds = 99999;
            let maxSeconds = 0;
            
            for (let i = 0;i < transcriptionSentences.length - 3;i += 4) {                    
                const sentenceTime = transcriptionSentences[i + 1];

                const textTokens = transcriptionSentences[i + 2].split(':');
                const speakerName = textTokens[0];
                const text = textTokens[1];

                const timeTokens = sentenceTime.split('-->');
                const startSeconds = parseFloat(timeTokens[0]);
                const endSeconds = parseFloat(timeTokens[1]);

                if (startSeconds < minSeconds) {
                    minSeconds = startSeconds;
                }

                if (endSeconds > maxSeconds) {
                    maxSeconds = endSeconds;
                }

                sentences.push({
                    startSeconds,
                    endSeconds,
                    speakerName,
                    text
                })
            }

            for (let i = 0;i < sentences.length;i++) {
                const sentence = sentences[i];

                const speakerIndex = speakerIndexMap[sentence.speakerName];
                const speakerColorIndex = speakerColorMap[sentence.speakerName];
                
                const startX = lerp(
                    sentence.startSeconds, 
                    cx, 
                    cx + barWidth,
                    minSeconds,
                    maxSeconds);
                    
                const endX = lerp(
                    sentence.endSeconds, 
                    cx, 
                    cx + barWidth,
                    minSeconds,
                    maxSeconds);

                context.fillStyle = speakerColors[speakerColorIndex];                

                cy = (speakerIndex * spacePerSpeaker) + sy;
                context.fillRect(startX, cy, endX - startX, barHeight);
            }
        }
    
        getMeetingInstance()
            .then(res => {
                const item = res.data;
                item.start_date = new Date(item.start_time);

                item.meeting_recordings.forEach((meetingRecording) => {
                    
                    if (meetingRecording.file_type === 'TRANSCRIPT') {
                        item.transcription_text = meetingRecording.transcription_text;
                    }
                    else if (meetingRecording.file_type === 'SPEAKERANALYTICS') {
                        if (meetingRecording.transcription_text) {
                            let callAnalytics = JSON.parse(meetingRecording.transcription_text);

                            item.call_analytics = {
                                speakingSecondsPerSpeaker: [],
                                totalSpeakingSeconds: callAnalytics.totalSpeakingSeconds 
                            };

                            for (let idx in callAnalytics.speakingSecondsPerSpeaker) {
                                const speakerSeconds : SpeakerSeconds = {
                                    speakerName: idx,
                                    speakingSeconds: callAnalytics.speakingSecondsPerSpeaker[idx]
                                };

                                item.call_analytics.speakingSecondsPerSpeaker.push(speakerSeconds);
                            }
                        }
                    }
                    else if (meetingRecording.file_type === 'MP4') {
                        item.video_url = meetingRecording.contents_url;
                    }
                });                

                setMeetingInstance(item);

                if (item) {
                    let meetingName = item.display_name || 
                        `ASPR Inc. | Ganesh Iyer | ${item.start_date.toLocaleDateString()}`;
                    
                    setMeetingName(meetingName);
                    drawSpeakerTimeline(item);
                }

                setDeletionReason('');                
            })
            .catch(error => {
                console.error({
                    message: 'Error fetching call recording',
                    error
                });
                
                return;
            }).finally(() => {
                setLoading(false);
            })
     }, [meetingInstanceId]);

    return (
        <div className="call-recording">                                     
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>{modalInfo.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalInfo.message}</Modal.Body>
                <Modal.Footer>
                {modalInfo.showSecondaryButton &&
                <Button variant="secondary" onClick={handleClose}>
                    {modalInfo.secondaryButtonName}
                </Button>     
                }
                {modalInfo.showPrimaryButton &&
                <Button variant="primary" onClick={async () => {await handleAccept();}}>
                     {modalInfo.primaryButtonName}
                </Button>}           
                </Modal.Footer>
            </Modal>
            <Container className='text-center'>               
                <Row>
                    <Col className="m-3">
                        <Link to='/call_recordings'>
                            <Button>
                                <ChevronLeft/>&nbsp;Back to list
                            </Button>
                        </Link>
                    </Col>
                    <Col xs={7}>
                        {   
                            meetingInstance &&
                            <div className="m-3">
                                <h6>                                    
                                    <FormGroup>
                                        <Form.Control
                                            type="text" 
                                            value={meetingName} 
                                            placeholder="Memorable meeting name" 
                                            onChange={event => setMeetingName(event.target.value)}/>
                                    </FormGroup>
                                </h6>
                                    <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip id="button-tooltip">
                                            <div style={{whiteSpace: 'pre-line'}}>{meetingInstance.meeting_participants.filter(mp => mp.display_name && mp.display_name.length > 0).map(mp => mp.display_name).join('\n')}</div>
                                            </Tooltip>}>
                                            <div>
                                                <Person size={'1.5em'} />{meetingInstance.meeting_participants.filter(mp => mp.display_name && mp.display_name.length > 0).length}
                                            </div>
                                    </OverlayTrigger>
                            </div>
                        }
                    </Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col>
                        {   
                            meetingInstance && 
                            <ReactPlayer 
                                className="mx-auto"
                                width={550}
                                height={310}
                                controls={true}
                                url={meetingInstance.video_url} />
                        }
                        <canvas ref={canvasRef} />    
                        <Row className='text-center'>
                            <Col></Col>
                            <Col xs={6}>
                                <Stack direction='horizontal' gap={2}>
                                <Form>
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                        <Form.Control type="text" value={deletionReason || ''} placeholder="reason for deletion" 
                                        onChange={event => setDeletionReason(event.target.value)}/>
                                    </Form.Group>
                                </Form>
                                <Button variant="danger" onClick={askDeleteCall}>
                                    <Trash/>
                                </Button>
                                </Stack>
                            </Col>
                            <Col></Col>
                        </Row>     
                    </Col>
                    <Col>
                        {   
                            meetingInstance && 
                            <Tabs
                                defaultActiveKey="transcript"
                                id="uncontrolled-tab-example"
                                className="mb-3">
                                <Tab eventKey="crm" title="CRM">
                                    <Card className="p-4" style={{ backgroundColor: '#F2F4F7'}}>                                        
                                        <h5>MEDDIC</h5>
                                    </Card>
                                    <div className="p-4" style={{overflowX: 'hidden', overflowY: 'scroll', height: 'calc(40vh)'}}>
                                        <h6>Salesforce Record</h6>
                                        <AsyncTypeahead
                                            filterBy={() => true}
                                            id='salesforce-record'
                                            isLoading={salesForceRecordsLoading}
                                            placeholder='Salesforce Record'
                                            options={salesforceRecords}
                                            onSearch={handleSalesForceRecordSearch}
                                            maxResults={5}
                                            labelKey='Name'
                                            selected={selectedSalesForceRecord}
                                            onChange={(selected: any) => {                                            
                                                setSelectedSalesForceRecord(selected);
                                            }}
                                        />    
                                    </div>                                    
                                </Tab>
                                <Tab className="text-start" eventKey="transcript" title="Transcript">
                                    <Card className="p-4" style={{ backgroundColor: '#F2F4F7'}}>                                        
                                        <h5>Transcription</h5>
                                    </Card>
                                    <div className="p-4" style={{overflowX: 'hidden', overflowY: 'scroll', height: 'calc(40vh)'}}>
                                        <div style={{ whiteSpace: 'pre-wrap' }}>
                                            {meetingInstance?.transcription_text}
                                        </div>
                                    </div>
                                </Tab>
                                <Tab className="text-start" eventKey="coaching" title="Coaching">
                                    <Card className="p-4" style={{ backgroundColor: '#F2F4F7'}}>                                        
                                        <h5>Coaching</h5>
                                    </Card>
                                    <div className="p-4" style={{overflowX: 'hidden', overflowY: 'scroll', height: 'calc(40vh)'}}>
                                        <Form>
                                            <Form.Group controlId="exampleForm.ControlInput1">
                                                <FormSelect
                                                    onChange={(e) => setSelectedCoacheeName(e.target.value)}>                                                                                                    
                                                    {callCoaching && 
                                                    callCoaching.length > 0 &&
                                                    callCoaching.map((cc) => {
                                                        return (<option value={cc.coachee_name}>{cc.coachee_name}</option>)
                                                    })}
                                                </FormSelect>
                                            </Form.Group>
                                        </Form>
                                        <Accordion>
                                            {selectedCoacheeName && 
                                            selectedCoacheeName.length > 0 &&
                                            callCoaching.map((cc) => {
                                                if (cc.coachee_name != selectedCoacheeName) {
                                                    return (<></>)
                                                } else {                                                   
                                                    return (
                                                        createCoachingAccordion(cc)                                                        
                                                    )
                                                }
                                            })}
                                        </Accordion>
                                    </div>
                                </Tab>
                                <Tab className="text-start" eventKey="recap" title="Recap">
                                    <Card className="p-4" style={{ backgroundColor: '#F2F4F7'}}>
                                        <h5>ASPR Inc. Meeting Summary</h5>
                                        <span><strong>Opporunity: </strong>name of opportunity&nbsp;&#9679;&nbsp;<strong>Deal: </strong>name of deal</span>
                                    </Card>
                                    <div className="p-4" style={{overflowX: 'hidden', overflowY: 'scroll', height: 'calc(40vh)'}}>
                                        <div className="mb-3">
                                            {formatTextWithNewlines(callSummary?.document_overview)}
                                        </div>
                                        <div className="mb-3">
                                            {formatTextWithNewlines(callSummary?.metrics)}
                                        </div>  
                                        <div className="mb-3">
                                            {formatTextWithNewlines(callSummary?.citations)}
                                        </div>    
                                        <div className="mb-3">
                                            {formatTextWithNewlines(callSummary?.sentiment)}                                        
                                        </div>                                   
                                    </div>
                                </Tab>
                                <Tab className="text-start" eventKey="followup" title="Follow-up">
                                    <Card className="p-4" style={{ backgroundColor: '#F2F4F7'}}>
                                        <h5>Follow-up Email</h5>
                                        Timely follow up is a key to success. Copy this email and send it to the meeting attendees.
                                    </Card>
                                    <div className="p-4" style={{overflowX: 'hidden', overflowY: 'scroll', height: 'calc(40vh)'}}>
                                        <h6>Subject</h6>
                                        <div className="mb-3">                                           
                                            {callSummary?.email_draft_subject}
                                        </div>
                                        <h6>Draft Content</h6>
                                        <div style={{ whiteSpace: 'pre-wrap' }}>
                                            {callSummary?.email_draft_content}
                                        </div>
                                    </div>
                                    <div className="mt-3 text-center">
                                        <Button 
                                            onClick={() => {navigator.clipboard.writeText(callSummary?.email_draft_content || '')}}>
                                            <Copy />&nbsp;Copy
                                        </Button>
                                    </div>
                                </Tab>
                                <Tab className="text-start" eventKey="advancedAnalytics" title="Advanced Analytics">
                                    <Card className="p-4" style={{ backgroundColor: '#F2F4F7'}}>
                                        <h5>Advanced Analytics</h5>
                                    </Card>
                                    <div className="p-4" style={{overflowX: 'hidden', overflowY: 'scroll', height: 'calc(40vh)'}}>
                                        {callAdvancedAnalytics && 
                                        callAdvancedAnalytics.map((ca) => {
                                            return (
                                                <div>
                                                    <h6>Insights</h6>
                                                    <div className="mb-3" style={{ whiteSpace: 'pre-wrap' }}>{ca.insights}</div>
                                                    <h6>Important Facts</h6>
                                                    <div className="mb-3" style={{ whiteSpace: 'pre-wrap' }}>{ca.important_facts}</div>
                                                    <h6>Strategic Implications</h6>
                                                    <div className="mb-3" style={{ whiteSpace: 'pre-wrap' }}>{ca.strategic_implications}</div>
                                                    <h6>Sentiment Dynamics</h6>
                                                    <div className="mb-3" style={{ whiteSpace: 'pre-wrap' }}>{ca.sentiment_dynamics}</div>
                                                    <h6>Conversation Flow</h6>
                                                    <div className="mb-3" style={{ whiteSpace: 'pre-wrap' }}>{ca.conversation_flow}</div>
                                                    <h6>Participants Ids</h6>
                                                    <div className="mb-3" style={{ whiteSpace: 'pre-wrap' }}>{ca.participants_ids}</div>
                                                    <h6>Playbook Entities</h6>
                                                    <div className="mb-3" style={{ whiteSpace: 'pre-wrap' }}>{ca.playbook_entities}</div>
                                                    <h6>Recommendations</h6>
                                                    <div className="mb-3" style={{ whiteSpace: 'pre-wrap' }}>{ca.recommendations}</div>
                                                    <h6>Actions Recommended</h6>
                                                    <div className="mb-3" style={{ whiteSpace: 'pre-wrap' }}>{ca.actions_recommended}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Tab>
                            </Tabs>                                                                     
                        }
                    </Col>
                </Row>
            </Container>        
        </div>
    );
}

export default CallRecordings;
