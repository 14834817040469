import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-modal';

const API_CHAT_ADD_FEEDBACK = "/api/chat_feedback/add";

// Set the app element for accessibility
Modal.setAppElement('#root');

interface Feedback {
    question: string;
    answer: string;
    feedback: string;
    rating: number;
}

const ChatFeedback: React.FC<{
    isOpen: boolean, onClose: () => void,
    question: string, answer: string
}> = (
    { isOpen, onClose, question, answer }
) => {
        const [formData, setFormData] = useState<Feedback>({
            question: question,
            answer: answer || "",
            feedback: '',
            rating: 5,
        });

        const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
            const { name, value } = e.target;
            setFormData({
                ...formData,
                [name]: name === 'rating' ? Number(value) : value,
            });
        };

        const handleSubmit = (e: React.FormEvent) => {
            e.preventDefault();
            // Send formData as JSON object
            fetch(API_CHAT_ADD_FEEDBACK, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            })
                .then(response => response.json())
                .then(data => console.log('Success:', data))
                .then(data => onClose())
                .catch(error => console.error('Error:', error));
        };

        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={onClose}
                contentLabel="Chat Feedback"
                style={{
                    content: {
                        top: '10%',
                        left: '10%',
                        right: '5%',
                        bottom: 'auto',
                        marginRight: '-5%',
                        transform: 'translate(-5%, -5%)',
                        // width: '400px',
                        padding: '20px'
                    },
                }}>
                <h2>Chat Feedback</h2>
                <Form>
                    <Form.Group className="mb-2" controlId="formData.question">
                        <Form.Label>Question:</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="question"
                            readOnly
                            value={formData.question}
                        />
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="formData.answer">
                        <Form.Label>Answer:</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="answer"
                            readOnly
                            value={formData.answer}
                        />
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="formData.rating">
                        <Form.Label>Rating (1-5): 1 being worst and 5 being best:</Form.Label>
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">
                                <div style={{whiteSpace: 'pre-line'}}>{formData.rating}</div>
                                </Tooltip>}>
                            <Form.Range                                                            
                                name="rating"
                                min={1}
                                max={5}
                                value={formData.rating}
                                onChange={handleChange}
                            />
                        </OverlayTrigger>                    
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="formData.feedback">
                        <Form.Label>Feedback:</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="feedback"
                            value={formData.feedback}
                            onChange={handleChange}
                            placeholder="Enter your review here..."
                        />
                    </Form.Group>
                    <Row className="d-flex align-items-center justify-content-between">
                        <Col md="auto">
                            <Button variant="secondary" onClick={onClose}>Close</Button>
                        </Col>
                        <Col xs="auto">
                            <Button onClick={handleSubmit} variant="primary"
                                disabled={!formData.rating || !formData.feedback}>
                                Submit Review
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    };

export default ChatFeedback;
