import React, { useState, useEffect, ChangeEvent } from "react";
import { useLoading } from "../context/LoadingContext";
import { toast } from "react-toastify";

const SEARCH_BY_OPEN_AI_API = "/api/prompt_sql/ask?stream=false"; // process.env.REACT_APP_BACKEND_BASE_URL
const DDL_OPTIONS_API = "/api/prompt_sql/ddl";
interface LlmProps {
  llmModel?: string;
  sqlQuery?: string;
  setSqlQuery?: any;
  setStoreSqlQuery?: any;
}

const ChatWindowComponent: React.FC<LlmProps> = ({
  llmModel,
  sqlQuery,
  setSqlQuery,
  setStoreSqlQuery,
}) => {
  const [userPrompt, setUserPrompt] = useState<string>("");
  const [ddlInclusion, setDdlInclusion] = useState<boolean>(false);
  const [ddlOptions, setDdlOptions] = useState<any[]>([]);
  const [selectedDdlQuery, setSelectedDdlQuery] = useState<string>("");
  const [selectedDdlQueries, setSelectedDdlQueries] = useState<string[]>([]);
  const { setLoading } = useLoading();

  const fetchDdlOptions = async () => {
    try {
      const response = await fetch(
        DDL_OPTIONS_API
      );
      if (!response.ok) {
        throw new Error("Failed to fetch DDL options");
      }
      const data = await response.json();
      setDdlOptions(
        data?.query_list && Array.isArray(data?.query_list)
          ? data.query_list
          : []
      );
      // setDdlOptions(data?.query_list);
    } catch (error) {
      toast.error("Error while fetching DDL options");
      console.error("Error fetching DDL options:", error);
    }
  };

  useEffect(() => {
    if (ddlInclusion) {
      fetchDdlOptions();
    }
  }, [ddlInclusion]);

  const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setUserPrompt(event.target.value);
  };

  const handleDdlSelectionChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedDdlQuery(event.target.value);
  };

  const handleSearchQuery = () => {
    let prompt = userPrompt;

    if (ddlInclusion && selectedDdlQuery) {
      // TODO: Move the prompts to the server side 
      prompt = `${userPrompt}\n\nPlease consider the following DDL for the above query:\n${selectedDdlQuery}`;
    }

    if (prompt && llmModel) {
      setLoading(true);
      fetch(
        SEARCH_BY_OPEN_AI_API,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userPrompt: prompt, llmModel }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setStoreSqlQuery(data?.data);
          setSqlQuery(data?.data);
          setLoading(false);
          const regex = /```sql\s*([\s\S]*?)\s*```/g;
          let match;
          const sqlBlocks = [];

          while ((match = regex.exec(data.data)) !== null) {
            sqlBlocks.push(match[1].trim());
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error:", error);
        });
    } else {
    }
  };

  return (
    <div className="mb-3 col-md-6 py-2">
      <label
        htmlFor="exampleFormControlTextarea1"
        className="form-label font-medium"
      >
        Chat Window
      </label>
      <textarea
        className="form-control resize-none"
        id="exampleFormControlTextarea1"
        rows={5}
        placeholder="Search your query here"
        value={userPrompt}
        onChange={handleTextAreaChange}
      />
      <div className="d-flex row pt-4 align-items-center">
        <div className="col-sm-6 mb-3">
          <div className="form-check form-switch">
            <input
              className="form-check-input border border-2 border-success"
              type="checkbox"
              id="flexSwitchCheckDefault1"
              checked={ddlInclusion}
              onChange={() => setDdlInclusion(!ddlInclusion)}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault1"
            >
              DDL inclusion
            </label>
          </div>
        </div>
        <div className="col-sm-6 mb-3">
          {ddlInclusion && ddlOptions.length > 0 && (
            <div className="form-floating">
              <select
                className="form-select"
                id="floatingSelect"
                aria-label="Floating label select example"
                value={selectedDdlQuery}
                onChange={handleDdlSelectionChange}
              >
                <option value="">Select DDL option</option>

                {ddlOptions?.map((option, index) => (
                  <option key={index} value={option?.sql_query}>
                    {option?.text_query}
                  </option>
                ))}
              </select>
              <label htmlFor="floatingSelect">DDL Menu</label>
            </div>
          )}
        </div>

        <div className="col-sm-12 mb-3 text-end">
          <div className="ms-auto justify-content-center">
            <button
              type="button"
              className="btn btn-info text-white"
              onClick={handleSearchQuery}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatWindowComponent;
