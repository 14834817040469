import React, { useState, ChangeEvent } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ConfirmationModal from "./ConfirmationModal";

const UPDATE_DDL_API = "/api/prompt_sql/ddl/"; // process.env.REACT_APP_BACKEND_BASE_URL
const CREATE_DDL_API = UPDATE_DDL_API;
const DDL_LIST_API = "/api/prompt_sql/ddl";
interface DDLTextEditingProps {
  ddlList?: any[];
  setDdlList: (ddlList: any[]) => void;
}

const DDLTextEditingComponent: React.FC<DDLTextEditingProps> = ({
  ddlList = [],
  setDdlList,
}) => {
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [textQuery, setTextQuery] = useState<string>("");
  const [sqlQuery, setSqlQuery] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleItemClick = (item: any) => {
    setSelectedItem(item);
    setTextQuery(item.text_query);
    setSqlQuery(item.sql_query);
  };

  const updateTextQuery = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTextQuery(event.target.value);
    setSelectedItem((prev: any) => ({
      ...prev,
      text_query: event.target.value,
    }));
  };

  const updateSqlQuery = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setSqlQuery(event.target.value);
    setSelectedItem((prev: any) => ({
      ...prev,
      sql_query: event.target.value,
    }));
  };

  const handleSave = async () => {
    if (!selectedItem) {
      return;
    }

    const updatedItem = {
      ...selectedItem,
      text_query: textQuery,
      sql_query: sqlQuery,
    };

    try {
      let response: any = (selectedItem.id) ?
        await fetch(
          `${UPDATE_DDL_API}${selectedItem.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedItem),
          }
        )
        :
        await fetch(
          `${CREATE_DDL_API}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedItem),
          }
        )
        ;

      if (!response.ok) {
        throw new Error(JSON.parse(await response.text()).message);
      }

      // TODO: fetchDdlOptions must be updated on the ChatWindow

      const data = await response.json();
      toast.success(data.message);
      const updatedListResponse = await fetch(
        DDL_LIST_API
      );
      if (!updatedListResponse.ok) {
        throw new Error("Failed to fetch updated ddl list");
      }

      const updatedListData = await updatedListResponse.json();
      setDdlList(updatedListData.query_list);
    } catch (error) {
      toast.error(`Error occured! ${error}`);
    } finally {
      setShowModal(false);
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="mt-5 pt-3 mb-3">
        <h3 className="fs-4 fw-semibold mb-4">DDL Text Editing and storage</h3>

        <div className="row">
          <div className="col-md-6 mb-4 border border-2">
            <div
              className="list-group"
              style={{
                height: "300px",
                overflowY: "scroll",
                minWidth: "100%",
              }}
            >
              {ddlList && ddlList?.length > 0 && (
                ddlList?.map((item: any, index: any) => (
                  <div
                    key={index}
                    className="list-group-item"
                    onClick={() => handleItemClick(item)}
                    style={{ cursor: "pointer" }}
                  >
                    {item?.id}
                    {")"} {item?.text_query}
                  </div>
                ))
              )}
              {/* { : (
                <div className="text-center">No data available</div>
              )} */}
              {
                <div
                className="list-group-item"
                onClick={() => handleItemClick({
                  text_query: "text",
                  sql_query: "SELECT * FROM users;",
                })}
                style={{ cursor: "pointer" }}
              >
                ... Add new query ...
              </div>

              }
            </div>
          </div>

          <div className="col-md-6 mb-4">
            <div
              className="list-group border border-2 p-4"
              style={{ height: "300px", overflowY: "scroll", minWidth: "100%" }}
            >
              {selectedItem ? (
                <div>
                  <h4>Details :</h4>
                  <p>
                    <strong>ID :</strong> {selectedItem.id}
                  </p>
                  <p>
                    <strong>Text Query :</strong>
                    <textarea
                      className="form-control resize-none"
                      id="exampleFormControl"
                      rows={2}
                      value={textQuery}
                      onChange={updateTextQuery}
                    />
                  </p>
                  <p>
                    <strong>SQL Query :</strong>
                    <textarea
                      className="form-control resize-none"
                      id="exampleFormControl"
                      rows={5}
                      value={sqlQuery}
                      onChange={updateSqlQuery}
                    />
                  </p>
                  <p>
                    <strong>Process Status :</strong>{" "}
                    {selectedItem.process_status}
                  </p>
                  <p>
                    <strong>Created At :</strong>{" "}
                    {new Date(selectedItem.created_at).toLocaleString()}
                  </p>
                  <p>
                    <strong>Updated At :</strong>{" "}
                    {new Date(selectedItem.updated_at).toLocaleString()}
                  </p>
                </div>
              ) : (
                <div className="text-center">
                  Select an item to view SQL Query
                </div>
              )}
            </div>
          </div>

          <div className="col-md-12 mb-0 text-end">
            <button
              type="button"
              className="btn btn-info mt-3 text-white"
              onClick={handleShowModal}
              disabled={!selectedItem}
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <ConfirmationModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handleSave={handleSave}
      />
    </>
  );
};

export default DDLTextEditingComponent;
