import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect, CSSProperties } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ChevronRight } from 'react-bootstrap-icons';
import { ChevronLeft } from 'react-bootstrap-icons';
import { Person } from 'react-bootstrap-icons';
import { Clock } from 'react-bootstrap-icons';
import { Calendar } from 'react-bootstrap-icons';
import { useLoading } from "../context/LoadingContext";

import axios from 'axios'
import { CallCoaching, CallCoachingSubject, MeetingInstance, MeetingInstancePaginated, SpeakerSeconds } from "../types/types";
axios.defaults.withCredentials = true;

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
  };

const API_URL = '/api/call_recordings';

const createCoachingPanel = (cc: CallCoaching) => {
    return (
        <div>
            {createCoachingEntry(cc, CallCoachingSubject.RapportBuilding)}
            {createCoachingEntry(cc, CallCoachingSubject.Discovery)}
            {createCoachingEntry(cc, CallCoachingSubject.ActiveListening)}
            {createCoachingEntry(cc, CallCoachingSubject.ObjectionHandling)}
            {createCoachingEntry(cc, CallCoachingSubject.ValueEnginerring)}
            {createCoachingEntry(cc, CallCoachingSubject.ClosingTactics)}
        </div>        
    )
}

const createCoachingEntry = (cc: CallCoaching, subject: CallCoachingSubject) => {
    let headerText =  '';
    let bodyText =  '';
    let score = 0;

    switch(subject) {
        case CallCoachingSubject.Overview:
            headerText = 'Overview';
            bodyText = cc.coaching_overview;
            break;

        case CallCoachingSubject.RapportBuilding:
            headerText = 'Rapport Building';
            bodyText = cc.rapport_building;
            score = cc.rapport_building_score;
            break;

        case CallCoachingSubject.Discovery:
            headerText = 'Discovery';
            bodyText = cc.discovery;
            score = cc.discovery_score;
            break;

        case CallCoachingSubject.ActiveListening:
            headerText = 'Active Listening';
            bodyText = cc.active_listening;
            score = cc.active_listening_score;
            break;

        case CallCoachingSubject.ObjectionHandling:
            headerText = 'Objection Handling';
            bodyText = cc.objection_handling;
            score = cc.objection_handling_score;
            break;

        case CallCoachingSubject.ValueEnginerring:
            headerText = 'Value Engineering';
            bodyText = cc.value_engineering;
            score = cc.value_engineering_score;
            break;

        case CallCoachingSubject.ClosingTactics:
            headerText = 'Closing Tactics';
            bodyText = cc.closing_tactics;
            score = cc.closing_tactics_score
            break;
    }

    return (
        <Row style={{fontSize: '.8em'}}>
            <Col xs={5}>
                <div >{headerText}</div>
            </Col>
            <Col>
                {score}
            </Col>
            <Col xs={5}>
                {createCoachingProgressBar(score)}
            </Col>
        </Row>
    )
}

const createCoachingProgressBar = (score: number) => {
    let variant = 'success;'
    
    if (score <= 1) {
        variant = 'danger';
    }
    else if (score <= 3) {
        variant = 'warning';
    }

    return (
        <ProgressBar variant={variant} now={score * 20} />
    )
};

// Main function to format text with newlines and specific stylings
function formatTextWithNewlines(text: string | undefined): JSX.Element[] {
    if (!text) {
        return [<span key="default">No text available</span>];
    }

    // Define patterns
    const boldOnlyPatterns = ['Summary:', 'Citations:', 'Key Points:', 'Subject:'];
    const newlineAndBoldPatterns = ['Outcomes:', 'Action Items/Next Steps:', 'Content:'];
    const boldItalicFirstSentencePatterns = ['Key Points:', 'Outcomes:', 'Action Items/Next Steps:', ];

    // Apply initial bold formatting
    let processedText = text.replace(new RegExp(`(${boldOnlyPatterns.join('|')})`, 'g'), '<strong>$1</strong>');
    processedText = processedText.replace(new RegExp(`(${newlineAndBoldPatterns.join('|')})`, 'g'), '\n<strong>$1</strong>');

    // Split the text for rendering and ensure newline management
    const lines = processedText.split('\n');
    return lines.map((item, index) => (
        <React.Fragment key={index}>
            <span dangerouslySetInnerHTML={{ __html: item }} />
            {index < lines.length - 1 && <br />}
        </React.Fragment>
    ));
}

const CallRecordings: React.FC<{}> = () => {
    const { setLoading } = useLoading();
    
    let [meetingInstances, setMeetingInstances] = useState<MeetingInstance[]>([]);
    let [skip, setSkip] = useState(0);
    let [take] = useState(4);
    let [page, setPage] = useState(1);
    let [totalPages, setTotalPages] = useState(1);
    
    function previousPage(e: any) {
        e.preventDefault();

        if (page <= 1) {
            return;
        }
        
        setLoading(true);
        setSkip(skip - take);
        setPage(page - 1);     
    }
    
    function nextPage(e: any) {
        e.preventDefault();

        if (page >= totalPages) {
            return;
        }

        setLoading(true);
        setSkip(skip + take);
        setPage(page + 1);        
    }       

    const str_pad_left = (s : string, pad: string, length :number) => {
        return (new Array(length + 1).join(pad) + s).slice(-length);
    }
        
    useEffect(() => {
        const loadMeetingInstances = async() => {
            const response = await axios.get<MeetingInstancePaginated>(
                `${API_URL}?skip=${skip}&take=${take}`
            );

            return response;
        };
       
        setLoading(true);
        loadMeetingInstances()
            .then(res => {    
                
                res.data.allMeetingInstances.forEach((item) => {
                    let hostName = '';
                    item.meeting_participants.forEach((mp) => {
                        if (mp.is_host && 
                            mp.display_name) {
                            hostName = mp.display_name;
                        }
                    });

                    if (item.coaching_results && item.coaching_results.length > 0) {
                        item.selected_coaching = item.coaching_results[0];

                        item.coaching_results.forEach((cr) => {
                            if (cr.coachee_name == hostName) {
                                item.selected_coaching = cr;
                            }
                        })
                    }
                    
                    item.start_date = new Date(item.start_time);
                    if (item.end_time) {
                        item.end_date = new Date(item.end_time);
                        item.length_seconds = (item.end_date.getTime() - item.start_date.getTime()) / 1000;
                        item.length_minutes = (item.length_seconds / 60);
                    } else {
                        item.length_seconds = 0;
                        item.length_minutes = 0;
                    }

                    item.meeting_recordings.forEach((meetingRecording) => {
                        
                        if (meetingRecording.file_type === 'TRANSCRIPT') {
                            item.transcription_text = meetingRecording.transcription_text;
                        }
                        else if (meetingRecording.file_type === 'SPEAKERANALYTICS') {
                            if (meetingRecording.transcription_text) {
                                let callAnalytics = JSON.parse(meetingRecording.transcription_text);

                                item.call_analytics = {
                                    speakingSecondsPerSpeaker: [],
                                    totalSpeakingSeconds: callAnalytics.totalSpeakingSeconds 
                                };

                                for (let idx in callAnalytics.speakingSecondsPerSpeaker) {
                                    const speakerSeconds : SpeakerSeconds = {
                                        speakerName: idx,
                                        speakingSeconds: callAnalytics.speakingSecondsPerSpeaker[idx]
                                    };

                                    item.call_analytics.speakingSecondsPerSpeaker.push(speakerSeconds);
                                }
                            }
                        }
                        else if (meetingRecording.file_type === 'MP4') {
                            item.video_url = meetingRecording.contents_url;
                        }
                    });
                });

                setMeetingInstances(res.data.allMeetingInstances);

                let pageCount = Math.floor((res.data.count - 1) / take) + 1;
                setTotalPages(pageCount);                
            })
            .catch(error => {
                console.error({
                    message: 'Error fetching call recordings',
                    error
                });
            }).finally(() => {
                setLoading(false);
            });
     }, [skip, take]);

    return (
        <div className="call-recordings">                                     
            <Container>      
                <Row className="m-3">
                    <Col>
                        <h3><span>Meetings</span> | <span>ASPR Inc. Workspace</span></h3>
                    </Col>
                </Row>
                <Row className="m-3">
                    <Col>
                        <Card style={{height: '100px'}}>
                            <span>FILTERS</span>
                        </Card>
                    </Col>
                </Row>                
                {meetingInstances.map((mi) => {
                    return (
                        <Row className="m-3">                            
                            <Col>                                
                                <Link style={{textDecoration: 'none'}} to={`/call_recording_details?id=${mi.meeting_instance_id}`}>
                                    <Card style={{width: '100%'}}>
                                        <Row>
                                            <Col className="text-center">
                                                <img 
                                                    onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; 
                                                    currentTarget.src = '../logo192.png';}} 
                                                    src={mi.thumbnail_url || '../logo192.png'} 
                                                    style={{height: '150px'}}/>
                                            </Col>
                                            <Col 
                                                xs={4}
                                                className="text-start p-2">
                                                <div>Ganesh Iyer</div>
                                                <div className="mb-3">
                                                    <h5>
                                                        {
                                                        mi.display_name ||
                                                        `ASPR Inc. | Ganesh Iyer | ${mi.start_date.toLocaleDateString()}`
                                                        }
                                                    </h5>
                                                </div>
                                                <div className="mb-4">
                                                    <Clock />&nbsp;
                                                    <span>{str_pad_left(mi.length_minutes.toFixed(0), '0', 2)}:{str_pad_left((mi.length_seconds % 60).toFixed(0), '0', 2)}</span>
                                                    &nbsp;&#9679;&nbsp;
                                                    <Calendar />&nbsp;
                                                    <span>{(((Date.now() - mi.start_date.getTime()) / 1000) / (60 * 60 * 24)).toFixed(0)} days ago</span>
                                                    &nbsp;&#9679;&nbsp;
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={<Tooltip id="button-tooltip">
                                                            <div style={{whiteSpace: 'pre-line'}}>{mi.meeting_participants.filter(mp => mp.display_name && mp.display_name.length > 0).map(mp => mp.display_name).join('\n')}</div>
                                                            </Tooltip>}>
                                                            <span>
                                                                <Person size={'1.5em'} />{mi.meeting_participants.filter(mp => mp.display_name && mp.display_name.length > 0).length}
                                                            </span>
                                                    </OverlayTrigger>
                                                </div>
                                            </Col>
                                            <Col xs={4}>
                                                <Card className="m-3 p-2" style={{height: '80%'}}>
                                                    {mi.selected_coaching &&                                                    
                                                    createCoachingPanel(mi.selected_coaching)}                                                    
                                                </Card>
                                            </Col>
                                        </Row>                                        
                                    </Card>
                                </Link>
                            </Col>
                        </Row>
                    )
                })}       
                <Row>
                    <Col className="m-3 text-start">
                        <Button variant="primary" onClick={previousPage}>
                            <ChevronLeft/>&nbsp;Previous
                        </Button>
                    </Col>
                    <Col xs={7} className="text-center m-3">                       
                        <span>Page {page} of {totalPages}</span>
                    </Col>
                    <Col className="m-3 text-end">
                        <Button variant="primary" onClick={nextPage}>
                            Next&nbsp;<ChevronRight/>
                        </Button>
                    </Col>
                </Row>         
            </Container>        
        </div>
    );
}

export default CallRecordings;
