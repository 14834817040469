import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { AuthUser } from '../context/AsprContext';
import { useAuth } from '../hooks/useAuth';
import { toast } from 'react-toastify';

const SignIn: React.FC<{}> = () => {
    const [authUser, setAuthUser] =
    useState<AuthUser>({ username: "", password: "" });
    const { isAuthenticated, isVerified, signIn } = useAuth();
    const navigate = useNavigate();

    // const [isAzureEnabled, setIsAzureEnabled] = useState(false);
    // const [isGoogleEnabled, setIsGoogleEnabled] = useState(false);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/sign_in');
        } else if (!isVerified) {
            navigate('/create_password');
        } else {
            navigate('/');
        }
    }, [isAuthenticated, isVerified, navigate])


    function handleSignInOAuth() {
        signIn('OAUTH');
    }

    function handleSignInPasswd() {
        if (!authUser.username) {
            toast.error('User email is required')
            return;
        };

        if (!authUser.password) {
            toast.error('Password is required');
            return;
        }
        signIn('PASSWD', authUser);
    }

    return (
        <div className="sign-up-container">
            <Container className="text-center">
                {/* <aside className="sign-buttons">
                    <button
                        className="btn-sign-azure"
                        disabled={!isZoomEnabled}
                        onClick={() => handleSignInOAuth()}
                    >
                        <img src="src/assets/logo_zoom.png" />
                    </button>
                </aside> */}
                <Row>
                    <Col>
                    </Col>
                    <Col>
                        <h1>Sign in</h1>
                        <h2>{isAuthenticated} {isVerified}</h2>
                        <Form>
                            <Form.Group className="mb-3" controlId="username">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="name@example.com"
                                    value={authUser.username || ''}
                                    onChange={(e) => setAuthUser({ ...authUser, username: e.target.value })} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password"
                                    value={authUser.password || ''}
                                    onChange={(e) => setAuthUser({ ...authUser, password: e.target.value })} />
                            </Form.Group>
                        </Form>
                        <Button onClick={() => handleSignInPasswd()}>Sign In</Button>
                    </Col>
                    <Col>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SignIn;
