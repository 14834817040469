export interface MeetingInstancePaginated {
    count : number
    allMeetingInstances: MeetingInstance[]
}

export interface Meeting {
    join_url: string
    topic?: string
}

export interface SpeakerSeconds {
    speakerName: string
    speakingSeconds: number
}

export interface CallAnalytics {
    totalSpeakingSeconds: number
    speakingSecondsPerSpeaker: SpeakerSeconds[]
}

export enum CallCoachingSubject {
    Overview = 'Overview',
    RapportBuilding = 'RapportBuilding',
    Discovery ='Discovery',
    ActiveListening = 'ActiveListening',
    ObjectionHandling = 'ObjectionHandling',
    ValueEnginerring = 'ValueEnginerring',
    ClosingTactics = 'ClosingTactics',
}

export interface CallAdvancedAnalytics {
    id: number
    insights: string
    important_facts: string
    strategic_implications: string
    sentiment_dynamics: string
    conversation_flow: string
    participants_ids: string
    playbook_entities: string
    recommendations: string
    actions_recommended: string
}

export interface CallCoaching {
    id: number
    rapport_building: string
    rapport_building_score: number
    discovery: string
    discovery_score: number
    active_listening: string
    active_listening_score: number
    objection_handling: string
    objection_handling_score: number
    value_engineering: string
    value_engineering_score: number
    closing_tactics: string
    closing_tactics_score: number
    coaching_overview: string
    coachee_name: string
    coachee_id: number | null
}

export interface CallSummary {
    citations: string
    document_overview: string
    email_draft_content: string
    email_draft_subject: string
    email_draft_status: string
    metrics: string
    participants: string
    sentiment: string
}

export interface MeetingInstance {
    meeting_instance_id: number;
    start_time: string;
    start_date: Date;
    end_time: string;
    end_date: Date;
    meeting_seconds: number
    topic?: string
    display_name?: string
    meeting: Meeting
    transcription_text?: string
    call_analytics?: CallAnalytics
    video_url?: string
    meeting_recordings: MeetingRecording[]
    meeting_participants: MeetingParticipant[]
    length_seconds: number
    length_minutes: number,
    coaching_results: CallCoaching[],
    selected_coaching: CallCoaching | null,
    thumbnail_url: string | null
}

export interface MeetingRecording {
    transcription_text?: string
    file_type?: string
    contents_url?: string
}

export interface MeetingParticipant {
    meeting_participant_id: number
    display_name?: string
    persistent_id?: string
    is_host?: boolean
    response_status: string
    first_name: string
    last_name: string
    extra_info: string
}

export interface ModalInfo {
    title?: string
    message?: string
    showPrimaryButton: boolean
    primaryButtonName?: string
    showSecondaryButton: boolean
    secondaryButtonName?: string
    onAccept?: ModalAcceptCallback
}

interface ModalAcceptCallback {
    (): void;
}
