import React, { useState, useEffect, ChangeEvent } from "react";
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";

import { useLoading } from "../context/LoadingContext";
import { Typeahead } from 'react-bootstrap-typeahead';

import axios from 'axios'
import { toast } from "react-toastify";
axios.defaults.withCredentials = true;

const API_URL_INTEGRATIONS = '/api/integrations';
const API_URL_SALESFORCE_OBJECTS = '/api/salesforce/objects';
const API_URL_SALESFORCE_FIELDS = '/api/salesforce/fields';
const API_URL_SALESFORCE_CONFIG = '/api/salesforce/configuration';

export enum IntegrationType {
    Unknown = 'Unknown',
    Zoom = 'Zoom',
    GoogleMeet = 'Google Meet',
    MSTeams = 'MS Teams',
    Webex = 'Webex',
    SalesForce = 'SalesForce'
}

interface IntegrationDetails {
    url: string,
    type: IntegrationType,
    exists: boolean
}

const Integrations: React.FC<{}> = () => {
    const [integrationDetails, setIntegrationDetails] = useState<IntegrationDetails[]>([]);
    const [showConfigureModal, setShowConfigureModal] = useState<boolean>(false);
    const [salesForceObjects, setSalesForceObjects] = useState<string[]>([]);
    const [selectedSFObject, setSelectedSFObject] = useState<any>([]);
    const [salesForceFields, setsalesForceFields] = useState<string[]>([]);
    const [selectedSFFields, setSelectedSFFields] = useState<any>({
        metrics: [''],
        economic_buyer: [''],
        decision_criteria: [''],
        decision_process: [''],
        identify_pain: [''],
        champion: [''],
    });

    const { setLoading } = useLoading();

    const createMEDDICSelectField = (fieldName: string) => {
        const fieldNameLower = fieldName.toLowerCase();
        const id = `salesforce-field-${fieldNameLower.replace(' ', '-')}-typeahead`;
        const fieldKey = `${fieldNameLower.replace(' ', '_')}`;

        return (
            <Row>
                <Col>
                    <FormGroup>
                        <Form.Label>
                            {fieldName}
                        </Form.Label>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Typeahead
                            id={id}
                            placeholder={`${fieldName} field`}
                            onChange={(selected) => {
                                const newState : any = {};
                                for(let idx in selectedSFFields) {
                                    newState[idx] = selectedSFFields[idx];
                                }
                                newState[fieldKey] = selected;
                                setSelectedSFFields(newState);
                            }}
                            selected={selectedSFFields[fieldKey]}
                            options={salesForceFields}
                            />                    
                    </FormGroup>
                </Col>
            </Row>                
        )
    }

    const handleConfigureModalClose = () => {
        setShowConfigureModal(false);
    };

    const handleConfigureModalAccept = async() => {
        if (!selectedSFObject || !salesForceFields) {
            return;
        }

        const saveConfiguration = async(config: any) => {
            return await axios.put<any>(
                `${API_URL_SALESFORCE_CONFIG}`,
                config);  
        };

        const salesforceConfig = {
            'meddic_object': selectedSFObject,
            'meddic_fields': selectedSFFields
        }

        setLoading(true);

        saveConfiguration(salesforceConfig)
            .then(() => {
                toast.success('The Salesforce configuration was saved successfully.')
            })
            .catch(error => {
                toast.error('There was an error saving the Salesforce configuration.')
            }).finally(() => {
                setLoading(false);
                setShowConfigureModal(false);
            })                
    };

    useEffect(() => {
        if (!selectedSFObject || 
            selectedSFObject.length == 0) {
            return;
        }

        setLoading(true);

        const loadSalesforceFields= async() => {
            const response = await axios.get<any>(
                `${API_URL_SALESFORCE_FIELDS}/${selectedSFObject}`
            );

            return response;
        };
    
        loadSalesforceFields()
            .then(res => {           
                setsalesForceFields(res.data);           
            })
            .catch(error => {
                console.error({
                    message: 'Error fetching sales force fields', 
                    error
                });
                return;
            }).finally(() => {
                setLoading(false);
            })
     }, [selectedSFObject]);

    useEffect(() => {
        if (!showConfigureModal) {
            return;
        }

        setLoading(true);

        const loadConfiguration = async() => {
            return await axios.get<any>(`${API_URL_SALESFORCE_CONFIG}`);  
        };

        const loadSalesforceObject = async() => {
            const response = await axios.get<any>(`${API_URL_SALESFORCE_OBJECTS}`);

            return response;
        };
            
        loadSalesforceObject()
            .then(res => {           
                setSalesForceObjects(res.data);     
                
                loadConfiguration()
                    .then(res => {
                        if (res && 
                            res.data) {

                            const body = JSON.parse(res.data);

                            if ('meddic_fields' in body) {
                                for(let idx in body.meddic_fields) {
                                    selectedSFFields[idx] = body.meddic_fields[idx];
                                }
                            }

                            if ('meddic_object' in body) {
                                setSelectedSFObject(body.meddic_object);
                            }
                        }
                    }).catch(error => {
                        console.error({
                            message: 'Error fetching sales force configuration', 
                            error
                        });
                        return;
                    });
            })
            .catch(error => {
                console.error({
                    message: 'Error fetching sales force objects', 
                    error
                });
                return;
            }).finally(() => {
                setLoading(false);
            })
     }, [showConfigureModal]);

    useEffect(() => {                
        const loadIntegrations = async() => {
            const response = await axios.get<IntegrationDetails[]>(
                `${API_URL_INTEGRATIONS}`
            );

            return response;
        };
    
        loadIntegrations()
            .then(res => {                      
                setIntegrationDetails(res.data);
            })
            .catch(error => {
                console.error({
                    message: 'Error fetching integrations:', 
                    error
                });
                return;
            });
     }, []);
    
    return (
        <Container>
            <Modal show={showConfigureModal} onHide={handleConfigureModalClose}>
                <Modal.Header closeButton>
                <Modal.Title>Configure Salesforce Integration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Form.Label>
                                    MEDDIC Salesforce Object
                                </Form.Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Typeahead
                                    id='salesforce-object-meddic-typeahead'
                                    placeholder='Salesforce Object for Storing MEDDIC Values'
                                    selected={selectedSFObject}
                                    onChange={setSelectedSFObject}
                                    options={salesForceObjects}
                                />              
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr />                    
                    {createMEDDICSelectField('Metrics')}
                    {createMEDDICSelectField('Economic buyer')}
                    {createMEDDICSelectField('Decision criteria')}
                    {createMEDDICSelectField('Decision process')}
                    {createMEDDICSelectField('Identify pain')}
                    {createMEDDICSelectField('Champion')}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleConfigureModalClose}>
                    Cancel
                </Button>     
                <Button variant="primary" onClick={async () => {await handleConfigureModalAccept();}}>
                     Save
                </Button>
                </Modal.Footer>
            </Modal>
            <Row>
                <Col></Col>
                <Col>
                    <Stack direction="horizontal" className="integrations">
                    {integrationDetails.map((id) => {  
                        return ( 
                            <Card key={id.type} style={{ width: '12rem', margin: '10px' }}>
                                <Card.Img variant="top" src={"../logo192.png"} />
                                
                                <Card.Body>
                                    <Card.Title>Integrate with {id.type}</Card.Title>
                                    {id.exists && id.type == IntegrationType.SalesForce && <Card.Text><Button onClick={() => setShowConfigureModal(true)} >Configure Integration</Button></Card.Text> }
                                    <Button href={id.url} variant="primary">{id.exists ? 'Re-Connect Integration' : 'Connect Integration ' }</Button>
                                </Card.Body>
                            </Card>                
                        )
                    })}     
                    </Stack>
                </Col>
                <Col></Col>            
            </Row>
        </Container>
    );
}

export default Integrations;
