import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

export const UpdateInformer: React.FC = () => {
    const [versionHash, setVersionHash] = useState("");
    const [updateNeeded, setUpdateNeeded] = useState(false);
    const versionHashRef = useRef(versionHash);

    useEffect(() => {
        versionHashRef.current = versionHash;
    }, [versionHash]);

    useEffect(() => {
        // Add a response interceptor
        const interceptor = axios.interceptors.response.use(function (response) {
            const xVersion = response?.headers['x-version'];
            if (xVersion) {
                if (versionHashRef.current === "") {
                    console.debug("current version hash", versionHashRef.current);
                    console.debug("changing versionHash", xVersion);
                    setVersionHash(xVersion);
                } else if (xVersion !== versionHashRef.current) { // if server version is different
                    window.localStorage.setItem('version-update-needed', 'true'); // set version update item so can refresh app later
                    setUpdateNeeded(true);
                }
            }
            return response; // continue with response
        });

        // Clean up the interceptor when the component unmounts or dependencies change
        return () => {
            axios.interceptors.response.eject(interceptor);
        };
    }, []);

    useEffect(() => {
        console.debug("Current versionHash:", versionHash);
    }, [versionHash]);

    // hook to check the app version on route change
    function useVersionCheck() {
        // check if a version update (refresh) needed on route change
        let location = useLocation();
        React.useLayoutEffect(() => {
            // if there is an update available and no state passed to route
            if (!location.state && window.localStorage.getItem('version-update-needed')) {
                window.localStorage.removeItem('version-update-needed'); // remove the storage object
                window.location.reload(); // refresh the browser
            }
        }, [location]);
    };

    useVersionCheck();

    function onClick() {
        window.localStorage.removeItem('version-update-needed'); // remove the storage object
        window.location.reload();                                // refresh the browser
    };

    return (
        <div hidden={!updateNeeded} style={{ textAlign: 'center' }}>A new version of the application is available.
            <a onClick={onClick} style={{ color: 'blue', fontWeight: 'bold', cursor: 'pointer' }}>Click here to update</a>
        </div>
    )
}
