import React, { useState, useEffect } from 'react';
import ChatInput, { ChatQuery } from '../components/ChatInput';
import ChatView, { Message, SentBy } from '../components/ChatView';
import ChatHistoryControl from '../components/ChatHistoryControl';
import axios from 'axios'
import { useLoading } from '../context/LoadingContext';
import { useAuth } from '../hooks/useAuth';
import Alert from 'react-bootstrap/Alert';
import { toast } from "react-toastify";

const API_QUERY_PATH = "/api/chat/ask";
const API_CHAT_MESSAGES_LIMIT = "/api/chat/config";
const API_CHAT_HISTORY = "/api/chat/history";
const CHAT_CONTEXT_SIZE = 5 * 2;

function getChatMessagesLimit() {
    return axios.get(API_CHAT_MESSAGES_LIMIT)
        .catch(function (error) {
            console.log(error);
        });
}

function setChatMessagesLimit(chat_messages_limit: number) {
    axios.post(
        API_CHAT_MESSAGES_LIMIT,
        {
            chat_messages_limit: chat_messages_limit
        },
    )
        .catch(function (error) {
            console.log(error);
        });
}

function getChatHistory() {
    return axios.get(API_CHAT_HISTORY)
        .catch(function (error) {
            console.log(error);
        });
}

function deleteChatHistory() {
    return axios.delete(API_CHAT_HISTORY)
        .catch(function (error) {
            console.log(error);
        });
}

function createErrorMessage(): Message {
    return {
        text: "Sorry, for some reason the bot is unable to answer. Try rephrasing the question...",
        sentBy: SentBy.bot,
        timestamp: new Date()
    };
}

type ContextMessage = Omit<Message, "crmData">;
function copyContextFromMessages(messages: Message[], limit: number): ContextMessage[] {
    return messages
        .filter((_, index) => index >= messages.length - limit)
        .map((item) => {
            let message: any = {};
            for (let key in item) {
                if (key !== "crmData") {
                    const value = (item as any)[key];
                    if (value) {
                        message[key] = value;
                    }
                }
            }
            return message as ContextMessage;
        });
}

const Chat: React.FC<{}> = () => {
    const { user } = useAuth();
    const [messages, setMessages] = useState<Message[]>([]);
    const [isBackendHealthy, setIsBackendHealthy] = useState(true);
    const [messagesLimit, setMessagesLimit] = useState(0);
    const { isLoading, setLoading } = useLoading();

    const handleSendMessage = async (query: ChatQuery) => {
        console.debug(messages);

        const userMessage: Message = {
            text: query.text,
            customer_id: query.customer_id,
            options: {
                useWebSearch: query.useWebSearch,
            },

            sentBy: SentBy.user,
            timestamp: new Date()
        };
        console.debug("User message:", userMessage);

        setLoading(true);
        setMessages([...messages, userMessage]);

        fetchResponse(userMessage)
            .catch(error => {
                toast.error(`We're sorry. There was a problem creating the response. Please try again. If the problem persists please contact ASPR support`);
                console.error('Error fetching ASPR response:', error);
                return createErrorMessage();
            })
            .then(message => {
                let newMessage = message;
                if ("error" in newMessage || !("llmResponse" in newMessage)) {
                    newMessage = createErrorMessage();
                }
                setMessages((prevMessages) => prevMessages.concat(message));
            })
            .finally(() => {
                setLoading(false);
                if (messages.length > messagesLimit) {
                    setMessages((prevMessages) => prevMessages.slice(messages.length - messagesLimit));
                }
            });
    };

    const fetchResponse = async (question: Message) => {
        let request: any = {
            question: question.text,
            // NOTE: This way does not guarantee that we will not catch the current user request.. Async... 
            messages: copyContextFromMessages(messages, CHAT_CONTEXT_SIZE)
        };
        if (question?.customer_id !== "") {
            request.customer_id = question.customer_id;
        }
        if (question?.options && Object.keys(question?.options).length > 0) {
            request.options = question.options;
        }

        setLoading(true);
        const response = await axios.post(
            API_QUERY_PATH,
            request,
            { headers: { 'Content-Type': 'application/json' } },
        );
        setLoading(false);

        const botMessage: Message = {
            llmResponse: response.data.llmResponse,
            crmData: response.data.HubspotData,
            residentKnowledgeData: response.data.residentKnowledgeData,
            dealInfo: response.data.dealInfo,
            sentBy: SentBy.bot,
            timestamp: new Date()
        };
        console.debug('Bot response:', botMessage);
        console.debug(response.data.crmData);


        return botMessage;
    };

    const checkBackendHealth = async () => {
        try {
            const response = await axios.get('/health');
            setIsBackendHealthy(response.data.status === 'ok');
        } catch (error) {
            console.error('Backend health check failed:', error);
            setIsBackendHealthy(false);
        }
    };

    useEffect(() => {
        Promise.all([getChatMessagesLimit(), getChatHistory()])
            .then(function ([res_messages_limit, res_chat_history]) {
                if (res_messages_limit?.data?.chat_messages_limit) {
                    setMessagesLimit(res_messages_limit.data.chat_messages_limit)
                }
                const new_messages = res_chat_history?.data as Array<Message>;
                if (new_messages) {
                    setMessages(new_messages);
                }
            });
    }, []);

    useEffect(() => {
        // align number of messages to be shown and save this value 
        let newMessagesLimit = messagesLimit;
        if (newMessagesLimit === 0) {
            newMessagesLimit = 2;
        }
        if (newMessagesLimit % 2 === 1) newMessagesLimit += 1;
        setMessagesLimit(newMessagesLimit);
    }, [messagesLimit])

    useEffect(() => {
        setLoading(false);

        const healthCheckInterval = setInterval(checkBackendHealth, 60000); // every 60 seconds
        return () => clearInterval(healthCheckInterval); // cleanup interval on component unmount
    }, [setLoading]);

    const cleanupHistory = () => {
        setMessages([]);
        deleteChatHistory();
    };

    const setNewMessagesLimit = (limit: number) => {
        setMessagesLimit(limit);
        setChatMessagesLimit(limit);
    }

    return (
        <>
            {user && !user.roles.includes('SuperAdmin') &&
                <Alert key="beta-alert" variant="primary" style={{ color: "#fff", backgroundColor: "#B6B5ED", textAlign: "center" }}>
                    Before proceeding, it is important to note that this is Private Beta invite-only testing.
                    We apologize for any instability or inconvenience when testing, and we appreciate your time, patience, and feedback in advance. 
                    Please reach out to <b>enboarder-support@aspr.ai</b> if you are facing any issues or have questions.<br />
                    The testing will run till 7th Sept 2024!
                </Alert>}

            <div className="chat">
                <ChatHistoryControl cleanupHistory={cleanupHistory} setLimit={setNewMessagesLimit} currentLimit={messagesLimit} />
                <ChatView messages={messages} isLoading={isLoading} />
                {!isBackendHealthy && <div className="backend-offline">The backend service is currently unavailable. Please try again later.</div>}
                <ChatInput onSendMessage={handleSendMessage} disabled={isLoading || !isBackendHealthy} />
            </div>
        </>
    );
};

export default Chat;
