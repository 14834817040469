import React, { useEffect, useState } from "react";
import ModelSelectionComponent from "../components/ModelSelection";
import ChatWindowComponent from "../components/ChatWindow";
import ResultWindowComponent from "../components/ResultsWindow";
import ResultTableComponent from "../components/ResultsTable";
import QueryListDisplay from "../components/QueryListDisplay";
import DDLTextSQLQueryEditing from "../components/DDLTextSQLQueryEditing";
import TextSQLQueryComponent from "../components/TextSqlQuery";

const DDL_LIST_API = "/api/prompt_sql/ddl"; // process.env.REACT_APP_BACKEND_BASE_URL
const SQL_QUERY_LIST = "/api/prompt_sql/sql-query";

const modelSelection = [
  {
    model: "ChatGPT-3.5-turbo",
    value: "gpt-3.5-turbo",
    selected: true,
  },
  {
    model: "ChatGPT-4-turbo",
    value: "gpt-4-turbo",
    selected: false,
  },
  {
    model: "GPT-4",
    value: "gpt-4",
    selected: false,
  },
  {
    model: "GPT-4o",
    value: "gpt-4o",
    selected: false,
  },
];

const PromptsSql: React.FC = () => {
  const [activeTab, setActiveTab] = useState("add-query");
  const [sqlQuery, setSqlQuery] = useState<string>("");
  const [storeSqlQuery, setStoreSqlQuery] = useState<string>("");
  const [llmModel, setLlmModel] = useState<string>("gpt-4-turbo");
  const [apiResponseResultData, setApiResponseResultData] = useState<any[]>([]);
  const [ddlList, setDdlList] = useState<any[]>([]);
  const [queryListTableData, setQueryListTableData] = useState<any[]>([]);

  const handleTabClick = (tab: string) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
    }
  };

  const handleSelectLlmModel = (event: any) => {
    setLlmModel(event.target.value);
  };

  const fetchData = async () => {
    try {
      const ddlResponse = await fetch(
        DDL_LIST_API
      );
      if (!ddlResponse.ok) {
        throw new Error("Failed to fetch DDL options");
      }
      const ddlData = await ddlResponse.json();
      setDdlList(ddlData?.query_list);

      const sqlQueryResponse = await fetch(
        SQL_QUERY_LIST
      );
      if (!sqlQueryResponse.ok) {
        throw new Error("Failed to fetch additional data");
      }
      const sqlQueryData = await sqlQueryResponse.json();
      setQueryListTableData(sqlQueryData?.query_list);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (activeTab === "edit-query") {
      fetchData();
    }
  }, [activeTab]);

  return (
    <div>
      <div className="container-fluid container-theme py-4">
        <div className="row">
          <div className="col-12 theme-nav pb-3">
            <ul
              className="nav nav-pills mb-4 border-bottom border-1 justify-content-center"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activeTab === "add-query" ? "active" : ""
                  }`}
                  id="add-query-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#add-query"
                  type="button"
                  role="tab"
                  aria-controls="add-query"
                  aria-selected={activeTab === "add-query"}
                  onClick={() => handleTabClick("add-query")}
                >
                  Add Query
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activeTab === "edit-query" ? "active" : ""
                  }`}
                  id="edit-query-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#edit-query"
                  type="button"
                  role="tab"
                  aria-controls="edit-query"
                  aria-selected={activeTab === "edit-query"}
                  onClick={() => handleTabClick("edit-query")}
                >
                  Edit Query
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div className="tab-content" id="myTabContent">
          <div
            className={`tab-pane fade ${
              activeTab === "add-query" ? "show active" : ""
            }`}
            id="add-query"
            role="tabpanel"
            aria-labelledby="add-query-tab"
          >
            <div className="border-0 position-relative">
              <div className="d-flex align-items-center justify-content-between flex-column">
                <span className="fs-4 fw-semibold mb-3">Add Query</span>
                <ModelSelectionComponent
                  modelSelection={modelSelection}
                  handleSelectLlmModel={handleSelectLlmModel}
                  llmModel={llmModel}
                />
              </div>
              <div className="d-flex row">
                <ChatWindowComponent
                  llmModel={llmModel}
                  sqlQuery={sqlQuery}
                  setSqlQuery={setSqlQuery}
                  setStoreSqlQuery={setStoreSqlQuery}
                />
                <ResultWindowComponent
                  sqlQuery={sqlQuery}
                  setSqlQuery={setSqlQuery}
                  storeSqlQuery={storeSqlQuery}
                  setApiResponseResultData={setApiResponseResultData}
                />
              </div>
              <ResultTableComponent
                apiResponseResultData={apiResponseResultData}
              />
              <div className="d-flex row">
                <TextSQLQueryComponent />
              </div>
            </div>
          </div>
          <div
            className={`tab-pane fade ${
              activeTab === "edit-query" ? "show active" : ""
            }`}
            id="edit-query"
            role="tabpanel"
            aria-labelledby="edit-query-tab"
          >
            {activeTab === "edit-query" && (
              <div className="border-0 position-relative">
                <h3 className="fs-4 fw-semibold mb-4 text-center">
                  Edit Query
                </h3>

                <div className="d-flex w-100 table-full-child">
                  <QueryListDisplay
                    queryListTableData={queryListTableData}
                    setQueryListTableData={setQueryListTableData}
                  />
                </div>
                <DDLTextSQLQueryEditing
                  ddlList={ddlList}
                  setDdlList={setDdlList}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromptsSql;
