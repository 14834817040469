import React, {useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Account } from '../context/Auth/auth.model';
import { FormGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';

import axios from 'axios'
axios.defaults.withCredentials = true;

const API_URL_ACCOUNTS_FETCH = '/api/company/account';
const API_URL_ACCOUNT_UPDATE  = '/api/company/account';
const API_URL_ACCOUNT_CREATE  = '/api/company/account';

interface AccountsPaginated {
    count : number,
    accounts: Account[]
}

const Users: React.FC<{}> = () => {
    let [accounts, setAccounts] = useState<Account[]>([]);
    let [skip, setSkip] = useState(0);
    let [take] = useState(20);
    let [page, setPage] = useState(1);
    let [totalPages, setTotalPages] = useState(1);
    let [inviteEmail, setInviteEmail] = useState('');

    function previousPage(e: any) {
        e.preventDefault();

        if (page <= 1) {
            return;
        }

        setSkip(skip - take);
        setPage(page - 1);     
    }
    
    function nextPage(e: any) {
        e.preventDefault();

        if (page >= totalPages) {
            return;
        }

        setSkip(skip + take);
        setPage(page + 1);        
    }       

    const handleInviteUser = async () => {
        try {
            if (!inviteEmail ||
                inviteEmail.length === 0 ||
                !inviteEmail.includes('@')) {
                    toast.error('Please provide a valid email address');
                    return;
                }

            let account : Account =  {
                email: inviteEmail,
                roles: 'user',
                is_verified: false,
                firstname: 'firstname',
                lastname: 'lastname',                

                account_id: 0,
                company_id: 0,
                uuid: ''                
            };

            const result = await axios.post<Account>(`${API_URL_ACCOUNT_CREATE}`, account);

            accounts.push(result.data);

            setAccounts([...accounts]);

            toast.success(`${inviteEmail} has been sent an invitation email. Once they validate their email address they can start using the app.`);
        } catch(err) {
            toast.error('There was a problem inviting the User.');
        }
    };

    const handleRoleChange = async (e: any) => {
        const oldAccounts = accounts;

        try {
            const index = e.target.dataset.index;
            const account = accounts[index]
            account.roles = e.target.value;

            setAccounts([...accounts]);

            await axios.patch(
                `${API_URL_ACCOUNT_UPDATE}`, { 
                    account_id: account.account_id,
                    roles: account.roles
                }
            );            
        } catch(err) {
            setAccounts(oldAccounts);

            toast.error('There was a problem updating the User.');
        }
    };
    
    useEffect(() => {     
        const loadUsers = async() => {
            const response = await axios.get<AccountsPaginated>(
                `${API_URL_ACCOUNTS_FETCH}?skip=${skip}&take=${take}`
            );

            return response;
        };      

        loadUsers()
            .then((response) => {
                setAccounts(response.data.accounts);

                let pageCount = Math.floor((response.data.count - 1) / take) + 1;
                setTotalPages(pageCount);
            })
            .catch((error) => {
                console.error({
                    message: 'Error loading users', 
                    error
                });

                return;
            });        
    }, [skip, take]);

    return (
        <div className="company-accounts">
            <Stack className='m-2' direction="horizontal" gap={2}>
                <Button onClick={previousPage}>
                    Previous
                </Button>
                <Button onClick={nextPage}>
                    Next
                </Button>
                <div>
                    Page {page} of {totalPages}
                </div>
            </Stack>
            <Stack className='m-2' direction="horizontal" gap={2}>
                <Form>
                    <FormGroup>
                        <Form.Control 
                            placeholder='email' 
                            value={inviteEmail} 
                            onChange={event => setInviteEmail(event.target.value)}>
                        </Form.Control>
                    </FormGroup>
                </Form>
                <Button variant='success' onClick={handleInviteUser}>
                    Invite User
                </Button>
            </Stack>
            <Table striped bordered hover style={{fontSize: '0.9em'}}>
                <thead>
                    <tr>
                        <th style={{width: '30%'}}>Email</th>
                        <th style={{width: '30%'}}>Name</th>
                        <th style={{width: '30%'}}>Role</th>
                        <th style={{width: '10%'}}>Verified</th>
                    </tr>
                </thead>
                <tbody>
                {accounts.map((el, i) => {
                    return(
                        <tr key={el.account_id}>
                            <td className="align-top">{el.email}</td>
                            <td className="align-top">{el.firstname} {el.lastname}</td>
                            <td className="align-top">
                                <Form.Select 
                                    key={el.account_id}
                                    data-index={i}
                                    value={el.roles}
                                    onChange={async (e) => await handleRoleChange(e)}>
                                    <option value="owner">Owner</option>
                                    <option value="admin">Admin</option>
                                    <option value="user">User</option>
                                </Form.Select>
                            </td>
                            <td className="align-top">{el.is_verified ? 'Y' : 'N'}</td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        </div>
    );
}

export default Users;
