import React, { useState, useEffect, CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import { useLoading } from "../context/LoadingContext";
import { toast } from "react-toastify";

import axios from 'axios'
axios.defaults.withCredentials = true;

const override: CSSProperties = {
    display: 'block',
    margin: 'mx-auto',
  };

const API_OAUTH_URL = '/api/oauth';
const API_GET_SESSION_URL = '/api/session';

const ConnectOAuth: React.FC<{}> = () => {
    const { setLoading } = useLoading();
    const navigate = useNavigate();
    let [searchParams] = useSearchParams();

    let oAuthSent = false;

    setLoading(true);

    useEffect(() => {     
        if (oAuthSent) {
            return;
        }
                
        oAuthSent = true;

        const code = searchParams.get('code');
        const state = searchParams.get('state');

        if (!state || !code) {
            toast.error('there was a problem creating the integration');
            throw new Error('there was a problem creating the integration');
        }

        const tokens = state.split(',');

        if (tokens.length !== 3) {
            toast.error('there was a problem creating the integration');
            throw new Error('there was a problem creating the integration');        
        }

        const doOAuth = async() => {
            await axios.get(`${API_OAUTH_URL}?state=${encodeURI(state)}&code=${encodeURI(code)}`);
            return await axios.get(API_GET_SESSION_URL);
        };

        doOAuth().then((response) => {           
            toast.success ('Thank you for connecting your integration.');
        }).catch((err) => {            
            console.error(err);
            toast.error('there was a problem creating the integration');
        }).finally(() => {
            navigate('/integrations');
            setLoading(false);
        });
    }, []);

    return (
        <></>
    );
}

export default ConnectOAuth;
