import React from "react";

interface ConfirmationModalProps {
  showModal: boolean;
  handleCloseModal: () => void;
  handleSave: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  showModal,
  handleCloseModal,
  handleSave,
}) => {
  return (
    <div
      className={`modal fade ${showModal ? "show d-block" : "d-none"}`}
      tabIndex={-1}
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Confirm Save</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={handleCloseModal}
            />
          </div>
          <div className="modal-body">
            <p>Are you sure you want to save this query?</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
            <button type="button" className="btn btn-info" onClick={handleSave}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
