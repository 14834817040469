import { createContext, useEffect, useState } from "react";
import { IAuth } from "./IAuth";
import {
    Account, AuthMethodKey, AuthContextData,
    AuthProviderProps, AuthMethod, AuthUser
} from "./auth.model";
import axios from "axios";

export const AuthContext = createContext<AuthContextData>(
    {} as AuthContextData,
);

export function AuthProvider({ children }: AuthProviderProps) {
    const [user, setUser] = useState<Account>();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authMethod, setAuthMethod] = useState<IAuth>();
    const [isVerified, setIsVerified] = useState(true);
    // const { setIsLoading } = useLoader();

    useEffect(() => {
        createAuthMethodFromStorage();
    }, []);

    async function signIn(method: AuthMethodKey, authUser?: AuthUser) {
        localStorage.setItem('@Auth.method', method);
        const auth = AuthMethod[method];
        setAuthMethod(auth);
        // setIsLoading(true);

        const user = await auth.signIn(authUser)
            .catch((err) => console.error(err));

        if (user !== undefined && user !== null && Object.keys(user).length > 0) {
            await setUserLogged(auth);
        } else {
            signOut();
        }
    }

    function signOut() {
        if (authMethod) {
            authMethod.signOut().catch(() => {});
        } else {
            console.error('auth method undefined');
        }
        setIsAuthenticated(false);
        setUser(undefined);
        localStorage.removeItem('@Auth.method');
        localStorage.removeItem('@Auth.user')
    }

    useEffect(() => {
        if (authMethod) {
            setUserLogged(authMethod);
        }
    }, [authMethod])

    async function setUserLogged(authMethod: IAuth) {
        const isAuthenticated = await authMethod.isAuthenticated();
        setIsAuthenticated(isAuthenticated);
        const user = await authMethod.getUser();
        setUser(user);
        setIsVerified(await authMethod.isVerified());
    }

    async function createAuthMethodFromStorage() {
        const storageMethod = await localStorage.getItem('@Auth.method');
        if (storageMethod) {
            const method = storageMethod as AuthMethodKey;
            const auth = AuthMethod[method];
            setAuthMethod(auth);
        }
    }

    function getAuthMethodType() {
        return authMethod ? authMethod.type : 'undefined';
    }

    const checkAuthStatus = async () => {
        if (isAuthenticated) {
            axios.get('/api/session').catch(error => {
                if (error?.response?.status === 401) {
                    signOut();
                }
            });
        }
    };

    useEffect(() => {
        checkAuthStatus();

        const authCheckInterval = setInterval(checkAuthStatus, 60000); // every 60 seconds
        return () => clearInterval(authCheckInterval); // cleanup interval on component unmount
    }, [checkAuthStatus]);

    return (
        <AuthContext.Provider
            value={{ user, isAuthenticated, isVerified, signIn, signOut, getAuthMethodType }}
        >
            {children}
        </AuthContext.Provider>
    );
}
