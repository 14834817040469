import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { toast } from "react-toastify";
import { useLoading } from "../context/LoadingContext";

const EXECUTE_SQL_QUERY_API = "/api/prompt_sql/sql-query/execute";

interface sqlprops {
  sqlQuery?: string;
  setSqlQuery?: any;
  storeSqlQuery?: string;
  setApiResponseResultData?: any;
}

const FixedHeightReactMarkdown = styled(ReactMarkdown)`
  padding: 10px; /* Add padding here */
`;

const ResultWindowComponent: React.FC<sqlprops> = ({
  sqlQuery,
  setSqlQuery,
  storeSqlQuery,
  setApiResponseResultData,
}) => {
  const [onlySQL, setOnlySQL] = useState(false);
  const [sqlBlocks, setSqlBlocks] = useState<string[]>([]);
  const { setLoading } = useLoading();

  useEffect(() => {
    if (storeSqlQuery) {
      if (onlySQL) {
        extractSQLCode(storeSqlQuery as any, (extractedSql, sqlQueries) => {
          setSqlQuery(extractedSql);
          setSqlBlocks(sqlQueries);
        });
      } else {
        setSqlQuery(storeSqlQuery);
        extractSQLCode(storeSqlQuery as any, (extractedSql, sqlQueries) => {
          setSqlBlocks(sqlQueries);
        });
      }
    }
  }, [storeSqlQuery]);

  const handleToggle = () => {
    setOnlySQL(!onlySQL);

    if (!onlySQL) {
      extractSQLCode(storeSqlQuery as any, (extractedSql, sqlQueries) => {
        setSqlQuery(extractedSql);
        setSqlBlocks(sqlQueries);
      });
    } else {
      setSqlQuery(storeSqlQuery);
    }
  };

  const extractSQLCode = (
    query: string,
    cb: (query: string, blocks: string[]) => void
  ) => {
    if (!query) cb("", []);
    const regex = /```sql\s*([\s\S]*?)\s*```/g;
    let match;
    // const sqlBlocks = [];
    const sqlQueries = [];

    while ((match = regex.exec(query)) !== null) {
      sqlQueries.push(match[1].trim());
    }
    const _SqlBlocks = sqlQueries.join("\n\n");
    cb(_SqlBlocks, sqlQueries);
  };
  const handleRunSQLQuery = async () => {
    if (!sqlQuery) return;
    setLoading(true);

    try {
      let _SqlQry = "";
      if (sqlBlocks.length > 0) {
        _SqlQry = sqlBlocks[0];
      }

      if (_SqlQry == "") {
        setLoading(false);
        toast.error("No SQL queries found in the input.");
        return;
      }

      const response = await fetch(
      EXECUTE_SQL_QUERY_API,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ query: _SqlQry }),
        }
      );

      if (!response.ok) {
        if (response.status == 400) {
          const text = await response.text();
          throw new Error(JSON.parse(text)?.message);
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }

      const data = (await response.json()) as any;
      setApiResponseResultData(data?.data);
      toast.success(data.message);
    } catch (error) {
      toast.error(`Error executing SQL query. ${error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mb-3 col-md-6 py-2">
      <label
        htmlFor="exampleFormControlTextarea1"
        className="form-label font-medium"
      >
        Result Window
      </label>
      <div className="form-control value-textarea">
        {onlySQL ? (
          <>
            <div>
              {sqlBlocks.map((elem, index) => (
                <p key={index}>{elem}</p>
              ))}
            </div>
          </>
        ) : (
          <FixedHeightReactMarkdown>{sqlQuery}</FixedHeightReactMarkdown>
        )}
      </div>
      <div className="d-flex row pt-4 align-items-center">
        <div className="col-sm-6 mb-3">
          <div className="form-check form-switch">
            <input
              className="form-check-input border border-2 border-success"
              type="checkbox"
              id="flexSwitchCheckDefault"
              checked={onlySQL}
              onChange={() => handleToggle()}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              Only SQL
            </label>
          </div>
        </div>
        <div className="col-sm-6 mb-3 text-end">
          <div className="ms-auto justify-content-center">
            <button
              onClick={handleRunSQLQuery}
              disabled={sqlBlocks.length > 1}
              type="button"
              className="btn btn-info text-white"
            >
              Run SQL Query
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultWindowComponent;
