import { IAuth } from "./IAuth"
import { AuthMethodKey, AuthUser, Account } from "./auth.model";

import axios from 'axios'
axios.defaults.withCredentials = true;

export class AuthOAuth implements IAuth {
    public type: AuthMethodKey = "OAUTH";

    constructor() {
        console.log('starting oauth');
    }

    public signIn = async (authUser?: AuthUser) => {
        // TODO: implement
       return undefined;
    }

    public signOut = async () => {    
        // TODO: implement    
        sessionStorage.removeItem('@Auth.email')
    };

    public isAuthenticated = () => {
        // TODO: implement
        const userStorage = sessionStorage.getItem('@Auth.email');
        return Promise.resolve(!!userStorage);
    };

    public isVerified = async () => {      
        return true;
    }

    public getUser = async () => {
        // TODO: implement
        const userStorage = await sessionStorage.getItem('@Auth.email');
        if (userStorage !== null) {
            return JSON.parse(userStorage) as Account;
        }
        return undefined;
    }

    public hasOwnerRole = async () => {
        // TODO: implement  
        return false;
    }

    public hasAdminRole = async () => {
        // TODO: implement  
        return false;
    }
}
