import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from "../hooks/useAuth";

const SignOut: React.FC<{}> = () => {
    const navigate = useNavigate();
    const { isAuthenticated, signOut } = useAuth();

    useEffect(() => {
        if (isAuthenticated) {
            signOut()
        }

        navigate('/sign_in');
    }, [signOut, isAuthenticated, navigate])

    return (
        <Outlet />
    );
}

export default SignOut;
