import { IAuth } from "./IAuth";
import { AuthMethodKey, AuthUser, Account, ContextModel } from "./auth.model";
import axios from "axios";
import { toast } from "react-toastify";

axios.defaults.withCredentials = true;

const API_URL_SIGNIN = '/api/login/signin';
const API_URL_LOGOUT = '/api/login/logout';

const SESSION_AUTH_ACCOUNT_KEY = "Auth.account";

export class AuthPasswd implements IAuth {
    type: AuthMethodKey = "PASSWD";

    constructor() {
        console.log('starting auth internal');
    }

    public signIn = async (authUser?: AuthUser | undefined) => {
        // const navigate = useNavigate();

        if (authUser === undefined) return undefined;

        try {
            const response = await axios.post<ContextModel>(
                API_URL_SIGNIN, {
                username: authUser.username,
                password: authUser.password
            });

            if (response?.status === 200) {
                const account = response.data.account;
                if (account) {
                    sessionStorage.setItem(SESSION_AUTH_ACCOUNT_KEY, JSON.stringify(account));
                    return account;
                }
            }

            return undefined;
        } catch (err) {
        }

        toast.error('Please supply valid credentials');
        return;
    }

    public signOut = async () => {
        const response = await axios.post<any>(API_URL_LOGOUT, {});
        // TODO: should we check the response?
        sessionStorage.setItem(SESSION_AUTH_ACCOUNT_KEY, "");
    }

    public isAuthenticated = async (): Promise<boolean> => {
        const account = await this.getUser();
        return account !== undefined && account !== null;
    }

    public isVerified = async () => {
        const account = await this.getUser();

        if (account === undefined || account == null) return true;

        return account?.is_verified;
    }

    public getUser = async (): Promise<Account | undefined> => {
        const text = sessionStorage.getItem(SESSION_AUTH_ACCOUNT_KEY);

        if (text === undefined || text === null || text === "") return undefined;

        try {
            const account: Account = JSON.parse(text);

            if (!account || !account.uuid) return undefined;

            return account;
        } catch (err) {
            return undefined;
        }
    };

    public hasOwnerRole = async () => {
        const account = await this.getUser();

        if (!account) {
            return false;
        }

        return account.roles.toLowerCase().includes('owner');
    }

    public hasAdminRole = async () => {
        const account = await this.getUser();
        
        if (!account) {
            return false;
        }

        return account.roles.toLowerCase().includes('admin');
    }
}
