import React, { useState } from 'react';
import Markdown from 'marked-react';
import ChatFeedback from './ChatFeedback';
import { Button, Row } from 'react-bootstrap';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from 'axios'
axios.defaults.withCredentials = true;

const API_FILE_URL = '/api/files';

export interface CrmData {
  [key: string]: string[];  // Each key maps to an array of strings
}

export enum SentBy {
  user = 'user',
  bot = 'bot'
}

export interface Message {
  text?: string;
  customer_id?: string;
  options?: { [key: string]: any };

  llmResponse?: any;
  crmData?: CrmData;
  residentKnowledgeData?: any;
  dealInfo?: any;

  timestamp: Date;
  sentBy: SentBy;
}

// FeedbackButton Component
const FeedbackButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Button onClick={onClick} variant="light">
      Leave Feedback
    </Button>
  );
};

interface ChatViewProps {
  messages: Message[];
  isLoading: boolean;
}

const ifVal = <T,>(val: T | null | undefined, fn: (val: T) => JSX.Element | null): JSX.Element | null => {
  return val == null ? null : fn(val);
}

const ChatView: React.FC<ChatViewProps> = ({ messages, isLoading }) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean[]>([]);

  const getFormattedDealInsights = (text: string) => {
    const listItems = text ? text.split('\n') : [];

    return (
      <ul>
        {
          listItems.map((li: any, i: number) => {
            return (<li key={"deal_insights_" + i}>{li.trim()}</li>);
          })
        }
      </ul>
    )
  }

  const openModal = (idx: number) => {
    modalIsOpen[idx] = true;

    setModalIsOpen([...modalIsOpen]);
  };

  const closeModal = (idx: number) => {
    modalIsOpen[idx] = false;

    setModalIsOpen([...modalIsOpen]);
  };

  const createFileButton = (item: any) => {
    return (
      <Button className='mb-3' onClick={() => getAPIFile(`${item.url}.pdf`)}>{item.title}</Button>
    )
  }

  const getAPIFile = async (fileId: string) => {
    try {
      var response = await axios.get<any>(`${API_FILE_URL}/${fileId}`, { responseType: 'blob' });

      if (!response || !response.data) {
        toast.error('There was a problem opening the file. If the problem persis please contact ASPR support.');
        return;
      }

      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const pdfWindow = window.open();
      if (!pdfWindow) {
        toast.error('There was a problem opening the file. If the problem persis please contact ASPR support.');
        return;
      }

      pdfWindow.location.href = fileURL;
    } catch {
      toast.error('There was a problem opening the file. If the problem persis please contact ASPR support.');
    }
  };

  return (
    <div className="chat-messages">
      {
        messages.map((message, index: number) => (
          <Row key={"chat_msg_" + index} className={`message ${message.sentBy === SentBy.user ? 'user-message' : 'bot-message'}`}>
            <span className="system">{message.sentBy === SentBy.user ? 'You: ' : 'ASPR response: '}</span>
            {(message.text) && (
              <Markdown>{message.text}</Markdown>
            )}
            {(message.llmResponse) && (
              <Markdown>{message.llmResponse.replace('Query Answer', '')}</Markdown>
            )}
            {
              message.crmData && Object.keys(message.crmData).length > 0 && (
                <div>
                  <h3>CRM Data:</h3>
                  <table className="table-container">
                    <thead>
                      <tr>
                        {Object.keys(message.crmData).map((key, idx) => (
                          <th key={"crm_" + idx}>{key}</th>
                        ))}
                      </tr>
                    </thead>
                    {
                      ifVal(message?.crmData, (crmData: CrmData) => (
                        <tbody>
                          {
                            Array.from({ length: crmData[Object.keys(crmData)[0]].length }).map((_, rowIdx) => (
                              <tr key={"crm_row_" + rowIdx}>
                                {Object.keys(crmData).map((key, colIdx) => (
                                  <td key={"crm_col_" + colIdx}>{crmData[key][rowIdx]}</td>
                                ))}
                              </tr>
                            ))}
                        </tbody>
                      ))}
                  </table>
                </div>
              )
            }
            {
              message.dealInfo && (message.dealInfo?.length > 0)
              && (
                <div className="deal_info" key={"div_deal_info_" + index}>
                  {
                    (message.dealInfo as Array<any>).some(item => item.insight) && (
                      <div className="deal_insights" key={"div_deal_insights_" + index}>
                        <h3 className="deal_insights" key={"h3_deal_insights_" + index}>Deal Insights</h3>
                        {(message.dealInfo as Array<any>).map((item, index) => {
                          return (
                            <>
                              <h5 key={"h5_deal_insights_" + index}>{item.title}</h5>
                              {getFormattedDealInsights(item.insight)}
                            </>
                          );
                        })
                        }
                      </div>
                    )
                  }
                  {
                    (message.dealInfo as Array<any>).some(item => item.url) && (
                      <div className="deal_briefing">
                        <h3 className="deal_briefing">ASPR AI Deal Briefing</h3>
                        {(message.dealInfo as Array<any>).map((item, index) => {
                          return (
                            <div key={"dli_u_" + index}>
                              {createFileButton(item)}
                            </div>
                          );
                        })}
                      </div>
                    )}
                </div>)
            }
            {
              message.sentBy === SentBy.bot && index > 0 && (
                <div>
                  <FeedbackButton onClick={() => { openModal(index) }} />
                  <ChatFeedback isOpen={modalIsOpen[index]}
                    onClose={() => closeModal(index)}
                    question={messages[index - 1].text ?? ""}
                    answer={message.text ?? message.llmResponse ?? ""}
                  />
                </div>
              )}
          </Row>
        ))}
      {
        isLoading && <div className="loading-message">ASPR is typing...</div>
      }
    </div>
  );
};

export default ChatView;
