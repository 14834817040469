import React, { useEffect, useState } from "react";
interface ResultTableComponentProps {
  apiResponseResultData?: any[];
}

const ResultTableComponent: React.FC<ResultTableComponentProps> = ({
  apiResponseResultData,
}) => {
  const [rowLimit, setRowLimit] = useState<number>(10);
  const [columns, setColumns] = useState<any>([]);

  useEffect(() => {
    if (apiResponseResultData?.length) {
      setColumns(Object.keys(apiResponseResultData[0]).sort());
    }
  }, [apiResponseResultData]);

  const handleRowLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const limit = parseInt(value, 10);
    if (!isNaN(limit) && limit > 0) {
      setRowLimit(limit);
    } else if (value === "") {
      setRowLimit(0);
    }
  };

  return (
    <div
      className="border p-4 result-box mb-4"
      style={{ maxHeight: "400px", overflowY: "auto" }}
    >
      <div className="d-flex justify-content-between">
        <div className="mb-3">
          <span className="fw-bold fs-5">Result Table</span>
        </div>
        <div className="input-group input-group-md mb-3 w-auto">
          <span className="input-group-text" id="inputGroup-sizing-sm">
            Row limit
          </span>
          <input
            type="number"
            style={{ maxWidth: "5rem" }}
            className="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-sm"
            value={rowLimit}
            onChange={handleRowLimitChange}
            min="1"
          />
        </div>
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              {apiResponseResultData &&
                columns?.map((item: string) => {
                  return (
                    <th scope="col" className="number">
                      {item}
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {apiResponseResultData && apiResponseResultData?.length > 0 ? (
              apiResponseResultData
                ?.slice(0, rowLimit)
                ?.map((item: any, index: number) => (
                  <tr key={item.index}>
                    {columns?.map((column: string) => (
                      <td className="text-center">
                        {typeof item[column] == "boolean"
                          ? item[column].toString()
                          : item[column] ?? ""}
                      </td>
                    ))}
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan={7} className="text-center">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ResultTableComponent;
