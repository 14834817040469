import React from "react";

type Model = {
  model: string;
  value: string;
};

interface ModelSelectionProps {
  modelSelection: Model[];
  handleSelectLlmModel: any;
  llmModel: string;
}

const ModelSelectionComponent: React.FC<ModelSelectionProps> = ({
  modelSelection,
  handleSelectLlmModel,
  llmModel,
}) => {
  return (
    <div className="top-section p-0 mb-3">
      <div className="form-floating">
        <select
          value={llmModel}
          className="form-select"
          id="floatingSelect"
          aria-label="Floating label select example"
          onChange={handleSelectLlmModel}
        >
          {modelSelection.map((item) => (
            <option key={item.value} value={item.value}>
              {item.model}
            </option>
          ))}
        </select>
        <label htmlFor="floatingSelect">Model Selection</label>
      </div>
    </div>
  );
};

export default ModelSelectionComponent;
